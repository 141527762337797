var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cấu hình App OneKids",
        visible: _vm.dialogVisible,
        width: "900px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.iconParentData != null
        ? _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c("div", { staticClass: "table-icon-1" }, [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Chức năng"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Hiển thị"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Mở khóa"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.messageName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.messageShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "messageShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.messageShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.message,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "message", $$v)
                                },
                                expression: "iconParentData.message",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.medicineName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.medicineShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "medicineShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.medicineShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.medicine,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "medicine", $$v)
                                },
                                expression: "iconParentData.medicine",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.absentName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.absentShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "absentShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.absentShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.absent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "absent", $$v)
                                },
                                expression: "iconParentData.absent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.albumName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.albumShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "albumShow", $$v)
                                },
                                expression: "iconParentData.albumShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.album,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "album", $$v)
                                },
                                expression: "iconParentData.album",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.evaluateName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.evaluateShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "evaluateShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.evaluateShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.evaluate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "evaluate", $$v)
                                },
                                expression: "iconParentData.evaluate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.attendanceName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.attendanceShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "attendanceShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.attendanceShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.attendance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "attendance",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.attendance",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.newTuitionName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.newTuitionShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "newTuitionShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.newTuitionShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.newTuition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "newTuition",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.newTuition",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.studentFeesName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.studentFeesShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "studentFeesShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.studentFeesShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.studentFees,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "studentFees",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.studentFees",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.learnName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.learnShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "learnShow", $$v)
                                },
                                expression: "iconParentData.learnShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.learn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "learn", $$v)
                                },
                                expression: "iconParentData.learn",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("10"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconParentData.menuName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.menuShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "menuShow", $$v)
                                },
                                expression: "iconParentData.menuShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.menu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "menu", $$v)
                                },
                                expression: "iconParentData.menu",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("11"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.videoName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.videoShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "videoShow", $$v)
                                },
                                expression: "iconParentData.videoShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.video,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "video", $$v)
                                },
                                expression: "iconParentData.video",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "table-icon-2" }, [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Chức năng"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Hiển thị"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Mở khóa"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("12"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.cameraName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.cameraShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "cameraShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.cameraShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.camera,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "camera", $$v)
                                },
                                expression: "iconParentData.camera",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("13"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.kidsInfoName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.kidsInfoShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "kidsInfoShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.kidsInfoShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.kidsInfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "kidsInfo", $$v)
                                },
                                expression: "iconParentData.kidsInfo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("14"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.facebookName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.facebookShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "facebookShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.facebookShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.facebook,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "facebook", $$v)
                                },
                                expression: "iconParentData.facebook",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("15"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconParentData.feedbackName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.feedbackShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconParentData,
                                    "feedbackShow",
                                    $$v
                                  )
                                },
                                expression: "iconParentData.feedbackShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.feedback,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "feedback", $$v)
                                },
                                expression: "iconParentData.feedback",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("16"),
                        ]),
                        _c("td", [_vm._v("Tin tức")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.newsShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "newsShow", $$v)
                                },
                                expression: "iconParentData.newsShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconParentData.news,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconParentData, "news", $$v)
                                },
                                expression: "iconParentData.news",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _c("div", { attrs: { align: "center" } }, [
            _vm._v("Không có dữ liệu"),
          ]),
      _c("br"),
      _c("br"),
      _vm.iconParentData != null
        ? _c("div", { attrs: { align: "center" } }, [
            _c("div", { attrs: { align: "center" } }, [
              _vm._v("Cấu hình màu nền cho Icon"),
            ]),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "circle-container" }, [
                _c("div", {
                  staticClass: "color-circle",
                  style: { backgroundColor: _vm.currentColor },
                }),
              ]),
              _c("div", { staticClass: "color-slider" }, [
                _c("label", { attrs: { for: "colorSlider" } }, [
                  _vm._v("Thay đổi màu :"),
                ]),
                _c("div", { staticClass: "slider-container" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.colorValue,
                        expression: "colorValue",
                      },
                    ],
                    style: { background: `hsl(${_vm.colorValue}, 100%, 85%)` },
                    attrs: {
                      type: "range",
                      id: "colorSlider",
                      min: "0",
                      max: "360",
                    },
                    domProps: { value: _vm.colorValue },
                    on: {
                      input: _vm.updateColors,
                      __r: function ($event) {
                        _vm.colorValue = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { attrs: { align: "center" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.iconParentData.backgroundColorIconParent,
                        expression: "iconParentData.backgroundColorIconParent",
                      },
                    ],
                    attrs: { type: "text", readonly: "" },
                    domProps: {
                      value: _vm.iconParentData.backgroundColorIconParent,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.iconParentData,
                          "backgroundColorIconParent",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("iconParentData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("iconParentData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }