var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thông tin cấu hình nhà trường",
        visible: _vm.dialogVisible,
        width: "1100px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.systemConfigSchoolData != null
        ? _c("div", { staticClass: "wrapper-table" }, [
            _c("table", { staticClass: "table-data", attrs: { border: "" } }, [
              _c("thead", [
                _c("tr", [
                  _c("td", { staticStyle: { width: "30px" } }, [_vm._v("STT")]),
                  _c("td", { staticStyle: { width: "800px" } }, [
                    _vm._v("Nội dung thiết lập"),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { width: "300px" },
                    },
                    [_vm._v("Thiết lập")]
                  ),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                  _c("td", [_vm._v("Có cần duyệt Album ảnh hay không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.album,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "album",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.album",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                  _c("td", [
                    _vm._v("Có cần duyệt đánh giá nhận xét hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.evaluate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "evaluate",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.evaluate\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                  _c("td", [
                    _vm._v("Có cho phép sửa đánh giá khi đã duyệt hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.editAproved,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "editAproved",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.editAproved\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                  _c("td", [
                    _vm._v("Có hiển thị chức năng đánh giá ngày hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.evaluateDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "evaluateDate",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.evaluateDate\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                  _c("td", [
                    _vm._v("Có hiển thị chức năng đánh giá tuần hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.evaluateWeek,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "evaluateWeek",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.evaluateWeek\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                  _c("td", [
                    _vm._v("Có hiển thị chức năng đánh giá tháng hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.evaluateMonth,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "evaluateMonth",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.evaluateMonth\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                  _c("td", [
                    _vm._v("Có hiển thị chức năng đánh giá định kỳ hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.evaluatePeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "evaluatePeriod",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .evaluatePeriod\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                  _c("td", [
                    _vm._v("Có gửi thông báo điểm danh đến hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .approvedAttendanceArrive,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "approvedAttendanceArrive",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .approvedAttendanceArrive\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                  _c("td", [
                    _vm._v(
                      "Giới hạn thời gian gửi điểm danh đến trước mấy giờ?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeAttendanceArrive,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeAttendanceArrive",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeAttendanceArrive\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("10")]),
                  _c("td", [
                    _vm._v("Có gửi thông báo điểm danh về hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .approvedAttendanceLeave,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "approvedAttendanceLeave",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .approvedAttendanceLeave\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("11")]),
                  _c("td", [
                    _vm._v(
                      "Giới hạn thời gian gửi điểm danh về trước mấy giờ?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeAttendanceLeave,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeAttendanceLeave",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeAttendanceLeave\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("12")]),
                  _c("td", [
                    _vm._v(
                      "Cho phép điểm danh lại với học sinh mấy ngày trước?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số ngày" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .againAttendance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "againAttendance",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.againAttendance\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("13")]),
                  _c("td", [
                    _vm._v(
                      "\n          Gửi đơn xin nghỉ phải trước ngày đầu tiên muốn nghỉ số ngày là?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số ngày" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .dateAbsent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "dateAbsent",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.dateAbsent\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("14")]),
                  _c("td", [_vm._v("Thời gian xin nghỉ phải trước mấy giờ?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeAbsent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeAbsent",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeAbsent\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("15")]),
                  _c("td", [
                    _vm._v("Mặc định có học sáng thứ 7 hàng tuần không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.morningSaturday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "morningSaturday",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .morningSaturday\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("16")]),
                  _c("td", [
                    _vm._v("Mặc định có học chiều thứ 7 hàng tuần không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.afternoonSaturday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "afternoonSaturday",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .afternoonSaturday\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("17")]),
                  _c("td", [
                    _vm._v("Mặc định có học tối thứ 7 hàng tuần không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.eveningSaturday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "eveningSaturday",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .eveningSaturday\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("18")]),
                  _c("td", [
                    _vm._v("Mặc định có học chủ nhật hàng tuần không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.sunday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "sunday",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.sunday\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("19")]),
                  _c("td", [
                    _vm._v("Hiển thị điểm danh đi học buổi sáng không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .morningAttendanceArrive,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "morningAttendanceArrive",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.morningAttendanceArrive\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("20")]),
                  _c("td", [
                    _vm._v("Hiển thị điểm danh đi học buổi chiều không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .afternoonAttendanceArrive,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "afternoonAttendanceArrive",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.afternoonAttendanceArrive\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("21")]),
                  _c("td", [
                    _vm._v("Hiển thị điểm danh đi học buổi tối không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .eveningAttendanceArrive,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "eveningAttendanceArrive",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.eveningAttendanceArrive\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("22")]),
                  _c("td", [_vm._v("Hiển thị điểm danh ăn bữa sáng không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.morningEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "morningEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.morningEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("23")]),
                  _c("td", [
                    _vm._v("Hiển thị điểm danh ăn bữa phụ sáng không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.secondMorningEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "secondMorningEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.secondMorningEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("24")]),
                  _c("td", [_vm._v("Hiển thị điểm danh ăn bữa trưa không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.lunchEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "lunchEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.lunchEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("25")]),
                  _c("td", [_vm._v("Hiển thị điểm danh ăn bữa chiều không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.afternoonEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "afternoonEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.afternoonEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("26")]),
                  _c("td", [
                    _vm._v("Hiển thị điểm danh ăn bữa phụ chiều không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.secondAfternoonEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "secondAfternoonEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.secondAfternoonEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("27")]),
                  _c("td", [_vm._v("Hiển thị điểm danh ăn bữa tối không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.eveningEat,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "eveningEat",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.eveningEat\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("28")]),
                  _c("td", [
                    _vm._v("Có cần duyệt các thông báo gửi đi hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.appSendApproved,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "appSendApproved",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.appSendApproved\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("29")]),
                  _c("td", [
                    _vm._v("Có cần duyệt nội dung thời khóa biểu hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.approvedSchedule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "approvedSchedule",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.approvedSchedule\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("30")]),
                  _c("td", [
                    _vm._v("Có cần duyệt nội dung thực đơn hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.approvedMenu,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "approvedMenu",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.approvedMenu\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("31")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép hiển thị số điện thoại của Phụ huynh với App Giáo viên?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.parentPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "parentPhone",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.parentPhone\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("32")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép Giáo viên xem thông tin chi tiết của Phụ huynh hay không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.parentInfo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "parentInfo",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.parentInfo\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("33")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép hiển thị số điện thoại của Giáo viên với App Phụ huynh?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.employeePhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "employeePhone",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.employeePhone\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("34")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép Phụ huynh xem thông tin chi tiết của Giáo viên không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.employeeInfo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "employeeInfo",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.employeeInfo\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("35")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép Phụ huynh xem lại lịch sử học của con khi đã chuyển\n          trường hay không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.historyViewParent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "historyViewParent",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.historyViewParent\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("36")]),
                  _c("td", [
                    _vm._v(
                      "\n          Cho phép Nhân viên xem lại lịch sử làm việc khi đã nghỉ làm ở\n          trường hày không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.historyViewTeacher,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "historyViewTeacher",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.historyViewTeacher\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("37")]),
                  _c("td", [
                    _vm._v(
                      "\n          Giáo viên có được xem chi tiết các khoản thu của học sinh lớp mình\n          hay không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.feesInfo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "feesInfo",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.feesInfo\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("38")]),
                  _c("td", [
                    _vm._v(
                      "\n          Giáo viên có được xem số tiền học phí của học sinh hay không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.feesNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "feesNumber",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.feesNumber\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("39")]),
                  _c("td", [
                    _vm._v(
                      "Thiết lập giờ đến quy định của Nhà trường với học sinh?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeArriveKid,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeArriveKid",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeArriveKid\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("40")]),
                  _c("td", [
                    _vm._v(
                      "Thiết lập giờ về quy định của Nhà trường với học sinh?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timePickupKid,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timePickupKid",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timePickupKid\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("41")]),
                  _c("td", [
                    _vm._v("Hiển thị thêm mẫu nhận xét của hệ thống?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.showEvaluateSys,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "showEvaluateSys",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.showEvaluateSys\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("42")]),
                  _c("td", [
                    _vm._v("Hiển thị thêm mẫu báo đến, báo về của hệ thống?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.showAttentendanceSys,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "showAttentendanceSys",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.showAttentendanceSys\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("43")]),
                  _c("td", [
                    _vm._v("Hiển thị thêm mẫu lời chúc của hệ thống?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.showWishSys,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "showWishSys",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.showWishSys\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("44")]),
                  _c("td", [
                    _vm._v(
                      "Thiết lập giờ đi làm buổi sáng của Nhân viên Nhà trường?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeArriveEmployee,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeArriveEmployee",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeArriveEmployee\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("45")]),
                  _c("td", [
                    _vm._v("Thiết lập giờ về của Nhân viên Nhà trường?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .timeLeaveEmployee,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "timeLeaveEmployee",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.timeLeaveEmployee\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("46")]),
                  _c("td", [_vm._v("Có tự động duyệt các phiếu thu không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.receiptNote,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "receiptNote",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.receiptNote\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("47")]),
                  _c("td", [_vm._v("Có tự động duyệt các phiếu chi không?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.paymentNote,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "paymentNote",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.paymentNote\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("48")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có sinh tự động hóa đơn công lương của Nhân viên cho tháng tiếp\n          theo không (sinh tự động vào ngày đầu tháng)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoBillSalaryEmployee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoBillSalaryEmployee",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoBillSalaryEmployee\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("49")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có sinh tự động các khoản công lương cho Nhân viên theo các khoản\n          mặc định?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoSignSalaryEmployee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoSignSalaryEmployee",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoSignSalaryEmployee\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("50")]),
                  _c("td", [
                    _vm._v(
                      "\n          Thiết lập ngày của tháng sẽ sinh tự động các khoản công lương cho\n          Nhân sự?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input-number", {
                        attrs: {
                          disabled:
                            !_vm.systemConfigSchoolData
                              .schoolConfigInSysResponse.autoSignSalaryEmployee,
                          min: 1,
                          max: 31,
                          size: "mini",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .autoNextmonthSalaryDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "autoNextmonthSalaryDate",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoNextmonthSalaryDate\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("51")]),
                  _c("td", [
                    _vm._v(
                      "Có tự động duyệt các khoản lương hàng tháng không?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.autoApprovedSalary,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoApprovedSalary",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoApprovedSalary\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("52")]),
                  _c("td", [
                    _vm._v(
                      "Có tự động khóa các khoản lương khi đã thanh toán đủ không?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoLockSalarySuccess,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoLockSalarySuccess",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoLockSalarySuccess\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("53")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có tự động hiển thị hóa đơn lương của cả tháng khi hóa đơn đã được\n          sinh ra không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.autoShowBillOfMonth,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoShowBillOfMonth",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoShowBillOfMonth\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("54")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có sinh tự động hóa đơn học phí của học sinh cho tháng tiếp theo\n          không (sinh tự động vào ngày đầu tháng)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.autoFeesApprovedKids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoFeesApprovedKids",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoFeesApprovedKids\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("55")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có sinh tự động các khoản thu với học sinh theo các khoản thu đã\n          đăng ký?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.autoGenerateFeesKids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoGenerateFeesKids",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoGenerateFeesKids\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("56")]),
                  _c("td", [
                    _vm._v(
                      "\n          Thiết lập ngày của tháng sẽ sinh tự động các khoản thu cho học\n          sinh?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input-number", {
                        attrs: {
                          disabled:
                            !_vm.systemConfigSchoolData
                              .schoolConfigInSysResponse.autoGenerateFeesKids,
                          min: 1,
                          max: 31,
                          size: "mini",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .autoNexMonthFeesDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "autoNexMonthFeesDate",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoNexMonthFeesDate\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("57")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có tự động duyệt các khoản thu của học sinh hàng tháng không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoApprovedFeesMonthKids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoApprovedFeesMonthKids",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoApprovedFeesMonthKids\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("58")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có tự động khóa các khoản thu khi Phụ huynh đã thanh toán đủ\n          không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoLockFeesSuccessKids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoLockFeesSuccessKids",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoLockFeesSuccessKids\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("59")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có tự động hiển thị hóa đơn học phí của cả tháng khi hóa đơn đã\n          được sinh ra không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.autoShowFeesMonth,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoShowFeesMonth",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesMonth\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("60")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có hiển thị thông tin các khoản thu dự kiến cho Phụ huynh học sinh\n          không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoShowFeesFutureKids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoShowFeesFutureKids",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesFutureKids\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("61")]),
                  _c("td", [
                    _vm._v("Có cho phép Phụ huynh truy cập ví hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.showWalletParent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "showWalletParent",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.showWalletParent\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("62")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có hiển thị thông tin khoản thu dự kiến của học sinh cho Giáo viên\n          không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .autoShowFeesFutureKidsForTeacher,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "autoShowFeesFutureKidsForTeacher",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesFutureKidsForTeacher\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("63")]),
                  _c("td", [
                    _vm._v("Hiển thị nút xóa Nhân sự, học sinh hay không?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.deleteStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "deleteStatus",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.deleteStatus\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("64")]),
                  _c("td", [_vm._v("Mã xử lý trùng tài khoản")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input", {
                        attrs: { size: "mini", maxlength: 6 },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .verifyCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "verifyCode",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse.verifyCode\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("65")]),
                  _c("td", [
                    _vm._v(
                      "\n          Có cho phép ra trường, nghỉ học khi chưa hoàn thành học phí không?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .checkCompleteFeesStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "checkCompleteFeesStatus",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.checkCompleteFeesStatus\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("66")]),
                  _c("td", [
                    _vm._v(
                      "Có gửi thông báo khi Nhà trường rút tiền từ ví không?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .notifyWalletOutStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "notifyWalletOutStatus",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.notifyWalletOutStatus\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("67")]),
                  _c("td", [
                    _vm._v(
                      "Hiện OneCam Plus trong danh sách Camera Nhà trường?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.showOnecamPlus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "showOnecamPlus",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.schoolConfigInSysResponse.showOnecamPlus\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("68")]),
                  _c("td", [_vm._v("Số lượng ảnh tối đa khi đăng Album?")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-input", {
                        attrs: { size: "mini" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.schoolConfigInSysResponse
                              .albumMaxNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse,
                              "albumMaxNumber",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.schoolConfigInSysResponse\n                  .albumMaxNumber\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("70")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn sáng theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.breakfastAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "breakfastAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.breakfastAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("71")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn phụ sáng theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.secondBreakfastAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "secondBreakfastAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.secondBreakfastAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("72")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn trưa theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.lunchAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "lunchAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.lunchAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("73")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn chiều theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.afternoonAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "afternoonAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.afternoonAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("74")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn phụ chiều theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.secondAfternoonAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "secondAfternoonAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.secondAfternoonAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("75")]),
                  _c("td", [
                    _vm._v(
                      "Áp dụng tự động điền điểm danh ăn tối theo đi học?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.dinnerAuto,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "dinnerAuto",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.dinnerAuto",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("76")]),
                  _c("td", [
                    _vm._v(
                      "Có tự động tạo điểm danh khi học sinh xin nghỉ học quá thời hạn quy định hay không?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse
                                .attendanceCreateLetter,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "attendanceCreateLetter",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.attendanceCreateLetter",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("77")]),
                  _c("td", [
                    _vm._v(
                      "Chọn trạng thái điểm danh mặc định khi xin nghỉ quá thời hạn?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.absentYesNoLetter,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "absentYesNoLetter",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.absentYesNoLetter",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có phép"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không phép"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index disable-row" }, [
                    _vm._v("68"),
                  ]),
                  _c("td", { staticClass: "disable-row" }, [
                    _vm._v("Thời gian gửi lời chúc mừng mặc định?"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .timeSendCelebrate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "timeSendCelebrate",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.timeSendCelebrate\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("69")]),
                  _c("td", [_vm._v("Chất lượng ảnh theo %?")]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập khoảng" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .qualityPicture,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "qualityPicture",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.qualityPicture\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("70")]),
                  _c("td", [_vm._v("Chất lượng ảnh theo kích thước?")]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập khoảng" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .widthPicture,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "widthPicture",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.widthPicture",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("71")]),
                  _c("td", [_vm._v("Chèn tiêu đề vào nội dung tin nhắn?")]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData.sysConfigResponse
                                .showTitleSms,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData.sysConfigResponse,
                                "showTitleSms",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.sysConfigResponse.showTitleSms",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("72")]),
                  _c("td", [
                    _vm._v("Nội dung tiêu đề mặc định trong tin nhắn SMS?"),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập tiêu đề" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .titleContentSms,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "titleContentSms",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.titleContentSms\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("73")]),
                  _c("td", [
                    _vm._v(
                      "\n          Hiện Phụ huynh đã đọc hay chưa đọc với App Giáo viên và Plus\n          (Thông báo, nhận xét)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData.sysConfigResponse
                                .appSendParent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData.sysConfigResponse,
                                "appSendParent",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.sysConfigResponse.appSendParent",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("74")]),
                  _c("td", [
                    _vm._v(
                      "\n          Hiện Giáo viên và Nhà trường đã đọc hay chưa đọc với App Phụ huynh\n          (Lời nhắn, dặn thuốc, xin nghỉ, góp ý)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData.sysConfigResponse
                                .appSendTeacher,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData.sysConfigResponse,
                                "appSendTeacher",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.sysConfigResponse.appSendTeacher\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("75")]),
                  _c("td", [
                    _vm._v(
                      "Số lần nhắc lại khi có nội dung mới với App Phụ huynh?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số lần" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .numberRemindParent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "numberRemindParent",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.numberRemindParent\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("76")]),
                  _c("td", [
                    _vm._v(
                      "Khoảng thời gian nhắc giữa 2 lần của App Phụ huynh (phút)?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .jumpTimeParent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "jumpTimeParent",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.jumpTimeParent\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("77")]),
                  _c("td", [
                    _vm._v(
                      "Số lần nhắc lại khi có nội dung mới với App Giáo viên?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số lần" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .numberRemindTeacher,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "numberRemindTeacher",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.numberRemindTeacher\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("78")]),
                  _c("td", [
                    _vm._v(
                      "Khoảng thời gian nhắc giữa 2 lần của App Giáo viên (phút)?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .jumpTimeTeacher,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "jumpTimeTeacher",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.jumpTimeTeacher\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("79")]),
                  _c("td", [
                    _vm._v("Số lần nhắc lại khi có nội dung mới với App Plus?"),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số lần" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .numberRemindPlus,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "numberRemindPlus",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.numberRemindPlus\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("80")]),
                  _c("td", [
                    _vm._v(
                      "Khoảng thời gian nhắc giữa 2 lần của App Plus (phút)?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .jumpTimePlus,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "jumpTimePlus",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.jumpTimePlus",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("81")]),
                  _c("td", [
                    _vm._v(
                      "\n          Thời gian bắt đầu nhắc lần đầu có thông tin mới (mặc định là 8h30)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .timeFrom,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "timeFrom",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.timeFrom",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("82")]),
                  _c("td", [
                    _vm._v(
                      "Thời gian cuối cùng có thể nhắc lần đầu (mặc định là 8h30)?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-time-picker", {
                        staticClass: "input-data",
                        staticStyle: { float: "left", width: "300px" },
                        attrs: {
                          size: "mini",
                          clearable: false,
                          type: "time",
                          "value-format": "HH:mm",
                          format: "HH:mm",
                          "picker-options": {
                            selectableRange: "06:00:00 - 21:00:00",
                          },
                          placeholder: "Chọn",
                        },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse.timeTo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "timeTo",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.timeTo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("83")]),
                  _c("td", [
                    _vm._v(
                      "\n          Số ngày quy định sau khi học sinh sang trường mới học sẽ báo Nhà\n          trường, nếu học sinh còn ở trạng thái đi học?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .numberOutSchool,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "numberOutSchool",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.numberOutSchool\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("84")]),
                  _c("td", [
                    _vm._v(
                      "\n          Số lần sẽ gửi thông báo cho Nhà trường với trường hợp sang trường\n          mới học mà chưa nghỉ học tại trường?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .numberNotify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "numberNotify",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.numberNotify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("85")]),
                  _c("td", [
                    _vm._v(
                      "\n          Khoảng thời gian gửi thông báo cho nhà trường khi học sinh học\n          trường mới (đơn vị là ngày/1 lần)?\n        "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số phút" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .jumpNumberOut,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "jumpNumberOut",
                              $$v
                            )
                          },
                          expression:
                            "systemConfigSchoolData.sysConfigResponse.jumpNumberOut",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("86")]),
                  _c("td", [
                    _vm._v(
                      "Có tự động xóa tài khoản khi không còn đối tượng con không?"
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData.sysConfigResponse
                                .deleteAccountStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData.sysConfigResponse,
                                "deleteAccountStatus",
                                $$v
                              )
                            },
                            expression:
                              "\n                systemConfigSchoolData.sysConfigResponse.deleteAccountStatus\n              ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("tr", { staticClass: "disable-row" }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("87")]),
                  _c("td", [
                    _vm._v("Thời gian xóa tự động tài khoản là sau mấy ngày?"),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "table-action",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "input-data",
                        attrs: { size: "mini", placeholder: "Nhập số ngày" },
                        model: {
                          value:
                            _vm.systemConfigSchoolData.sysConfigResponse
                              .deleteAccountDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.systemConfigSchoolData.sysConfigResponse,
                              "deleteAccountDate",
                              $$v
                            )
                          },
                          expression:
                            "\n                systemConfigSchoolData.sysConfigResponse.deleteAccountDate\n              ",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("88")]),
                  _c("td", [_vm._v("Thiết lập thời gian đón muộn")]),
                  _c("td", { staticClass: "table-action" }, [
                    _c("table", { staticClass: "center-table" }, [
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse.statusBlock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "statusBlock",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.statusBlock",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Block"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Ngày"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [_vm._v("Nhập số phút")]
                            ),
                            _c(
                              "el-input-number",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  min: 0,
                                  step: 1,
                                  placeholder: "Nhập số phút",
                                },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse.blockLeaveKid,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "blockLeaveKid",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.blockLeaveKid",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  >\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse.statusUpDown,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "statusUpDown",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.statusUpDown",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Làm tròn lên"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Làm tròn xuống"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("89")]),
                  _c("td", [
                    _vm._v(
                      "Cấu hình thứ tự hiển thị tin tức màn Home trên App?"
                    ),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "sttShowTabHomeTable" }, [
                      _c("table", [
                        _c("tr", [
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Tin tức")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Facebook")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Video")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Website trường")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Tiktok")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Onekids Page")]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                attrs: { disabled: true },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse.activeShowNews,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowNews",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowNews",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeShowFacebook },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse
                                      .activeShowFacebook,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowFacebook",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowFacebook",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeShowVideo },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse
                                      .activeShowVideo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowVideo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowVideo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeShowWebsiteSchool },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse
                                      .activeShowWebsiteSchool,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowWebsiteSchool",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteSchool",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeShowTiktok },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse
                                      .activeShowTiktok,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowTiktok",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeShowOneKidsPage },
                                model: {
                                  value:
                                    _vm.systemConfigSchoolData
                                      .schoolConfigInSysResponse
                                      .activeShowOneKidsPage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse,
                                      "activeShowOneKidsPage",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder: "Chọn thứ tự hiển thị tin tức",
                                  },
                                  on: { change: _vm.changeNumberShowNews },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse.sttShowNews,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowNews",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowNews,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder:
                                      "Chọn thứ tự hiển thị Facebook",
                                    disabled:
                                      !_vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .activeShowFacebook,
                                  },
                                  on: { change: _vm.changeNumberShowFacebook },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .sttShowFacebook,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowFacebook",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowFacebook,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder: "Chọn thứ tự hiển thị Video",
                                    disabled:
                                      !_vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .activeShowVideo,
                                  },
                                  on: { change: _vm.changeNumberShowVideo },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse.sttShowVideo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowVideo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowVideo,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder:
                                      "Chọn thứ tự hiển thị trang web của trường",
                                    disabled:
                                      !_vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .activeShowWebsiteSchool,
                                  },
                                  on: {
                                    change: _vm.changeNumberShowWebsiteSchool,
                                  },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .sttShowWebsiteSchool,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowWebsiteSchool",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowWebsiteSchool,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder: "Chọn thứ tự hiển thị Tiktok",
                                    disabled:
                                      !_vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .activeShowTiktok,
                                  },
                                  on: { change: _vm.changeNumberShowTiktok },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .sttShowTiktok,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowTiktok",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowTiktok,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common",
                                  attrs: {
                                    placeholder:
                                      "Chọn thứ tự hiển thị trang web của Onekids",
                                    disabled:
                                      !_vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .activeShowOneKidsPage,
                                  },
                                  on: {
                                    change: _vm.changeNumberShowOneKidsPage,
                                  },
                                  model: {
                                    value:
                                      _vm.systemConfigSchoolData
                                        .schoolConfigInSysResponse
                                        .sttShowOneKidsPage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse,
                                        "sttShowOneKidsPage",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage",
                                  },
                                },
                                _vm._l(_vm.numberSelect, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      disabled:
                                        item ===
                                        _vm.systemConfigSchoolData
                                          .schoolConfigInSysResponse
                                          .sttShowOneKidsPage,
                                      value: item,
                                      label: `${item}`,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "table-index" }, [_vm._v("90")]),
                  _c("td", [
                    _vm._v(
                      "Có tự động xóa dữ liệu điểm danh, nhận xét trùng hay không?"
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.systemConfigSchoolData
                                .schoolConfigInSysResponse.attendanceDuplicate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.systemConfigSchoolData
                                  .schoolConfigInSysResponse,
                                "attendanceDuplicate",
                                $$v
                              )
                            },
                            expression:
                              "systemConfigSchoolData.schoolConfigInSysResponse.attendanceDuplicate",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("Có"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("Không"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Không có dữ liệu"),
          ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("systemConfigSchoolData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("systemConfigSchoolData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }