<template>
  <div>
    <div>
      <div style="margin-bottom: 20px">
        <!-- chọn khối -->
        <el-select
            style="width: 200px; margin-right: 6px"
            v-model="dataSearch.idAgent"
            @change="findAllAppIconForSchool()"
            placeholder="Chọn đại lý"
            clearable
        >
          <el-option
              v-for="item in agentList"
              :key="item.id"
              :label="item.agentName"
              :value="item.id"
          ></el-option>
        </el-select>
        <!-- chọn trường -->
        <el-select
            style="width: 250px; margin-right: 5px"
            filterable
            clearable
            v-model="dataSearch.schoolName"
            placeholder="Chọn trường"
            @change="findAllAppIconForSchool()"
        >
          <el-option
              v-for="item in schoolList"
              :key="item.id"
              :value="item.schoolName"
              :label="item.id+' - '+item.schoolName"
          >
            <span>{{ item.id }}</span> -
            <span>{{ item.schoolName }}</span>
          </el-option>
        </el-select>
        <!-- Chọn trạng thái -->
        <el-select
            style="width: 170px; margin-right: 5px"
            v-model="dataSearch.schoolActive"
            placeholder="Chọn trường"
            @change="findAllAppIconForSchool()"
        >
          <el-option
              v-for="item in schoolActiveList"
              :key="item.id"
              :value="item.value"
              :label="item.label"
          >
          </el-option>
        </el-select>
      </div>

      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          v-loading="loadingData"
          :element-loading-text="$tableLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255, 0)"
          highlight-current-row
          :data="appIconForSchoolList"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange"
          :max-height="$tableMaxHeight"
          border
      >
        <el-table-column
            type="selection"
            align="center"
            width="55"
            fixed
        ></el-table-column>
        <el-table-column
            type="index"
            label="STT"
            width="50"
            align="center"
            fixed
        ></el-table-column>
        <el-table-column label="Tên trường" min-width="150" fixed>
          <template slot-scope="scope">
            <span>{{ scope.row.id }} - {{ scope.row.schoolName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tình trạng" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.schoolActive">Kích hoạt</span>
            <span v-else>Chưa kích hoạt</span>
          </template>
        </el-table-column>
        <el-table-column label="Notify Firebase" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingNotifyMethod(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="Icon lock" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingIconLockMethod(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="App OneKids" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingAppIconParent(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="App Teacher" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingAppIconTeacher(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="App Plus" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingAppIconPlus(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="Giới hạn thiết bị" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="oneCamConfigMethod(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="School config" align="center" width="150">
          <template slot-scope="scope">
            <el-button
                type="success"
                size="mini"
                @click="settingSchoolConfig(scope.row.id)"
            >Thiết lập
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-dropdown @command="handleCommandAction" style="float: right ; margin-top: 10px">
          <el-button class="button-over" type="success">
            Tác vụ
            <i class="el-icon-caret-bottom"/>
          </el-button>
          <el-dropdown-menu>
            <el-dropdown-item command="turnOffAutomaticInvoiceGeneration">Tắt tự động sinh hóa đơn</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <AttendanceSampleUpdate
        :dialogVisible="showUpdateDialog"
        @dialog-close="dialogCloseUpdateMethod()"
        ref="AttendanceSampleUpdate"
    />
    <SchoolSystemIconParentDialog
        :dialogVisible="showIconParentDialog"
        @dialog-close="dialogCloseCreateIconParentMethod()"
        ref="SchoolSystemIconParentDialog"
    />
    <SchoolSystemIconTeacherDialog
        :dialogVisible="showIconTeacherDialog"
        @dialog-close="dialogCloseCreateIconTeacherMethod()"
        ref="SchoolSystemIconTeacherDialog"
    />
    <SchoolSystemIconPlusDialog
        :dialogVisible="showIconPlusDialog"
        @dialog-close="dialogCloseCreateIconPlusMethod()"
        ref="SchoolSystemIconPlusDialog"
    />
    <SchoolSystemConfigDialog
        :dialogVisible="showSchoolConfigDialog"
        @dialog-close="dialogCloseSchoolConfigMethod()"
        ref="SchoolSystemConfigDialog"
    />
    <NotifyFirebaseDialog
        :dialogVisible="showNotifyFirebaseDialog"
        @dialog-close="closesNotifyFirebaseDialogMethod()"
        ref="NotifyFirebaseDialog"
    />
    <IconLockDialog
        :dialogVisible="showIconLockDialog"
        @dialog-close="closesIconLockDialogMethod()"
        ref="IconLockDialog"
    />
    <OneCamConfigDialog
        :dialogVisible="showOneCamConfigDialog"
        @dialog-close="closesOneCamConfigDialogMethod()"
        ref="OneCamConfigDialog"
    />
  </div>
</template>

<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import AgentService from "@/services/AdminService/AgentService";

import AttendanceSampleUpdate from "./AttendanceSampleUpdate.vue";
import SchoolSystemIconParentDialog from "./SchoolSystemIconParentDialog.vue";
import SchoolSystemIconTeacherDialog from "./SchoolSystemIconTeacherDialog.vue";
import SchoolSystemIconPlusDialog from "./SchoolSystemIconPlusDialog.vue";
import SchoolSystemConfigDialog from "./SchoolSystemConfigDialog.vue";
import NotifyFirebaseDialog from "./NotifyFirebaseDialog.vue";
import IconLockDialog from "./IconLockDialog.vue";
import OneCamConfigDialog from "./OneCamConfigDialog.vue";
import systemConfigService from "@/services/AdminService/SystemConfigService";

export default {
  components: {
    AttendanceSampleUpdate,
    SchoolSystemIconParentDialog,
    SchoolSystemIconTeacherDialog,
    SchoolSystemIconPlusDialog,
    SchoolSystemConfigDialog,
    NotifyFirebaseDialog,
    IconLockDialog,
    OneCamConfigDialog,
  },
  watch:{
    multipleSelection(val){
      this.handleSelectionChange(val)
    }
  },
  data() {
    return {
      schoolList: [],
      loadingData: true,
      textTable: "",
      activeTabName: "arriveTab",
      appIconForSchoolList: [],
      agentList: [],
      leaveDataList: [],
      wishDataList: [],
      loaddingButton: false,
      showUpdateDialog: false,
      showAddMediaDialog: false,
      showIconParentDialog: false,
      showIconTeacherDialog: false,
      showIconPlusDialog: false,
      showSchoolConfigDialog: false,
      showNotifyFirebaseDialog: false,
      showIconLockDialog: false,
      showOneCamConfigDialog: false,
      loaddingButtonClass: false,
      multipleSelection: [],
      listIdSchoolSelect: [],
      dataSearch: {
        idAgent: "",
        schoolName: "",
        schoolActive: true,
      },
      schoolActiveList: [
        {
          id: 1, value: true, label: "Kích hoạt"
        },
        {
          id: 2, value: false, label: "Chưa kích hoạt"
        },
      ]
    };
  },
  methods: {
    handleCommandAction(command) {
      if (command === 'turnOffAutomaticInvoiceGeneration') {
        this.turnOffAutomaticInvoiceGeneration()
      }
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (!row.schoolActive) {
        return "color: red";
      }
    },
    handleClickTab(tab) {
      let tabNameClick = tab.name;
      if (tabNameClick == "wishTab") {
        this.findAllMediaMethod();
      }
    },
    handleCommand(command) {
      if (command == "exportDate") {
        this.exportExcelAttendanceDate();
      } else if (command == "exportMonth") {
        this.exportExcelAttendanceMonth();
      }
    },
    dialogCloseUpdateMethod() {
      this.showUpdateDialog = false;
      this.findAllAppIconForSchool();
    },
    dialogCloseAddMediaMethod() {
      this.showAddMediaDialog = false;
      this.findAllAppIconForSchool();
    },
    updateAttendanceSampleRow(row) {
      this.showUpdateDialog = true;
      this.$refs.AttendanceSampleUpdate.getAttendanceSampleUpdateInitial(row);
    },
    dialogCloseCreateIconParentMethod() {
      this.showIconParentDialog = false;
    },
    dialogCloseCreateIconTeacherMethod() {
      this.showIconTeacherDialog = false;
    },
    dialogCloseCreateIconPlusMethod() {
      this.showIconPlusDialog = false;
    },
    dialogCloseSchoolConfigMethod() {
      this.showSchoolConfigDialog = false;
    },
    closesNotifyFirebaseDialogMethod() {
      this.showNotifyFirebaseDialog = false;
    },
    closesIconLockDialogMethod() {
      this.showIconLockDialog = false;
    },
    closesOneCamConfigDialogMethod() {
      this.showOneCamConfigDialog = false;
    },

    /**
     * update icon parent
     */
    settingAppIconParent(id) {
      setTimeout(() => {
        this.showIconParentDialog = true;
      }, 100);

      this.$refs.SchoolSystemIconParentDialog.getDataInitial(id);
    },
    /**
     * update icon teacher
     */
    settingAppIconTeacher(id) {
      setTimeout(() => {
        this.showIconTeacherDialog = true;
      }, 100);

      this.$refs.SchoolSystemIconTeacherDialog.getDataInitial(id);
    },
    /**
     * update icon plus
     */
    settingAppIconPlus(id) {
      setTimeout(() => {
        this.showIconPlusDialog = true;
      }, 100);
      this.$refs.SchoolSystemIconPlusDialog.getDataInitial(id);
    },
    settingSchoolConfig(id) {
      setTimeout(() => {
        this.showSchoolConfigDialog = true;
      }, 100);
      this.$refs.SchoolSystemConfigDialog.getDataInitial(id);
    },
    settingNotifyMethod(id) {
      setTimeout(() => {
        this.showNotifyFirebaseDialog = true;
      }, 100);
      this.$refs.NotifyFirebaseDialog.getDataInitial(id);
    },
    settingIconLockMethod(id) {
      setTimeout(() => {
        this.showIconLockDialog = true;
      }, 100);
      this.$refs.IconLockDialog.getDataInitial(id);
    },
    oneCamConfigMethod(id) {
      setTimeout(() => {
        this.showOneCamConfigDialog = true;
      }, 100);
      this.$refs.OneCamConfigDialog.getDataInitial(id);
    },
    /**
     * xóa 1 mẫu điểm danh
     */
    deleteAttendanceSampleOneMethod(row) {
      this.$confirm(
          "Bạn có chắc chắn muốn xóa mẫu điểm danh đã chọn?",
          "Xóa mẫu điểm danh",
          {
            distinguishCancelAndClose: true,
            closeOnClickModal: false,
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(() => {
        SystemConfigService.deleteAttendanceSampleOne(row.id)
            .then((resp) => {
              let data = resp.data.data;
              console.log(data);
              this.$message({
                message: "Xóa thành công",
                type: "success",
              });
              this.findAllAppIconForSchool();
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: "Xóa thất bại",
                type: "error",
              });
            });
      });
    },

    /**
     * tìm kiếm tất cả các loại điểm danh đến và về
     */
    findAllAppIconForSchool() {
      SystemConfigService.findSchoolConfig(
          this.dataSearch.idAgent,
          this.dataSearch.schoolName,
          this.dataSearch.schoolActive
      )
          .then((resp) => {
            this.appIconForSchoolList = resp.data.data;
            if (this.dataSearch.schoolName === "") {
              this.schoolList = this.appIconForSchoolList
            }
          })
          .catch((err) => {
            console.log(err);
            this.appIconForSchoolList = null;
          })
          .finally(() => {
            if (this.appIconForSchoolList.length === 0) {
              this.textTable = this.$tableEmpty;
            }
            this.loadingData = false;
          });
    },
    // chon đại lý
    findAllAgentUnique() {
      AgentService.getAll()
          .then((resp) => {
            this.agentList = resp.data.data.agentList;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.listIdSchoolSelect = []
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.listIdSchoolSelect.push(this.multipleSelection[i].id)
        }
      } else {
        this.listIdSchoolSelect = []
        this.$refs.multipleTable.clearSelection();
      }

    },

    async turnOffAutomaticInvoiceGeneration() {
      const data = {
        listIdSchoolSelect: this.listIdSchoolSelect
      }
      if (this.listIdSchoolSelect.length > 0){
        this.$confirm(
            " Bạn có chắc chắn muốn tắt tự động sinh hóa đơn, khoản thu cho các trường được chọn?",
            "Thông báo!",
            {
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            }
        ).then(async () => {
          await systemConfigService.turnOffAutomaticInvoiceGeneration(data).then(() => {
            this.$message.success({
              showClose: true,
              message: "Cập nhật thay đổi thành công",
              duration: 5000,
            });
            this.multipleSelection = [];
          })

        })
      }else {
        this.$message.error({
          showClose: true,
          message: "Chưa có trường nào được chọn",
          duration: 5000,
        });
      }


    }

  },

  beforeMount() {
    this.findAllAgentUnique();
    this.findAllAppIconForSchool();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #78a5e7;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 10px 0;
}

/deep/ .el-table .cell {
  line-height: 15px;
}

/deep/ .el-table th > .cell {
  font-size: 13px;
}

/deep/ .el-checkbox__inner {
  border: 1px solid gray;
}

.input-common {
  margin-right: 5px;
}

.button-bottom {
  border-radius: 0;
  margin-left: 3px;
}

.button-click {
  float: right;
  margin: 20px 0 40px 0;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: #5fb44a;
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 #0000001a;
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
