var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cấu hình App Plus",
        visible: _vm.dialogVisible,
        width: "900px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.iconPlusData != null
        ? _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c("div", { staticClass: "table-icon-1" }, [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Chức năng"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Hiển thị"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Mở khóa"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.employeeName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.employeeShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "employeeShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.employeeShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.employee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "employee", $$v)
                                },
                                expression: "iconPlusData.employee",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.kidsName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.kidsShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "kidsShow", $$v)
                                },
                                expression: "iconPlusData.kidsShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.kids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "kids", $$v)
                                },
                                expression: "iconPlusData.kids",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.messageName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.messageShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "messageShow", $$v)
                                },
                                expression: "iconPlusData.messageShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.message,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "message", $$v)
                                },
                                expression: "iconPlusData.message",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.medicineName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.medicineShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "medicineShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.medicineShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.medicine,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "medicine", $$v)
                                },
                                expression: "iconPlusData.medicine",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.absentName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.absentShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "absentShow", $$v)
                                },
                                expression: "iconPlusData.absentShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.absent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "absent", $$v)
                                },
                                expression: "iconPlusData.absent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.attendanceName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.attendanceShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "attendanceShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.attendanceShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.attendance,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "attendance", $$v)
                                },
                                expression: "iconPlusData.attendance",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.evaluateName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.evaluateShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "evaluateShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.evaluateShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.evaluate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "evaluate", $$v)
                                },
                                expression: "iconPlusData.evaluate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.albumName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.albumShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "albumShow", $$v)
                                },
                                expression: "iconPlusData.albumShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.album,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "album", $$v)
                                },
                                expression: "iconPlusData.album",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.healthName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.healthShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "healthShow", $$v)
                                },
                                expression: "iconPlusData.healthShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.health,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "health", $$v)
                                },
                                expression: "iconPlusData.health",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("10"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.learnName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.learnShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "learnShow", $$v)
                                },
                                expression: "iconPlusData.learnShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.learn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "learn", $$v)
                                },
                                expression: "iconPlusData.learn",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("11"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.menuName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.menuShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "menuShow", $$v)
                                },
                                expression: "iconPlusData.menuShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.menu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "menu", $$v)
                                },
                                expression: "iconPlusData.menu",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("12"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.videoName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.videoShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "videoShow", $$v)
                                },
                                expression: "iconPlusData.videoShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.video,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "video", $$v)
                                },
                                expression: "iconPlusData.video",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("13"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.cameraName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.cameraShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "cameraShow", $$v)
                                },
                                expression: "iconPlusData.cameraShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.camera,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "camera", $$v)
                                },
                                expression: "iconPlusData.camera",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("14"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.birthdayName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.birthdayShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "birthdayShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.birthdayShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.birthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "birthday", $$v)
                                },
                                expression: "iconPlusData.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("15"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.studentFeesNameNew)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.studentFeesShowNew,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "studentFeesShowNew",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.studentFeesShowNew",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.studentFeesNew,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "studentFeesNew",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.studentFeesNew",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("16"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.studentFeesName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.studentFeesShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "studentFeesShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.studentFeesShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.studentFees,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "studentFees", $$v)
                                },
                                expression: "iconPlusData.studentFees",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("17"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.salaryName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.salaryShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "salaryShow", $$v)
                                },
                                expression: "iconPlusData.salaryShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.salary,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "salary", $$v)
                                },
                                expression: "iconPlusData.salary",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("18"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.utilityName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.utilityShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "utilityShow", $$v)
                                },
                                expression: "iconPlusData.utilityShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.utility,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "utility", $$v)
                                },
                                expression: "iconPlusData.utility",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("19"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.facebookName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.facebookShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "facebookShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.facebookShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.facebook,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "facebook", $$v)
                                },
                                expression: "iconPlusData.facebook",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("20"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.feedbackName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.feedbackShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "feedbackShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.feedbackShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.feedback,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "feedback", $$v)
                                },
                                expression: "iconPlusData.feedback",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "table-icon-2" }, [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Chức năng"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Hiển thị"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Mở khóa"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("21"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.smsAppHistoryName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.smsAppHistoryShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "smsAppHistoryShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.smsAppHistoryShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.smsAppHistory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "smsAppHistory",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.smsAppHistory",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("22"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.notifyName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.notifyShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "notifyShow", $$v)
                                },
                                expression: "iconPlusData.notifyShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.notify,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "notify", $$v)
                                },
                                expression: "iconPlusData.notify",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("23"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.supportName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.supportShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "supportShow", $$v)
                                },
                                expression: "iconPlusData.supportShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.support,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "support", $$v)
                                },
                                expression: "iconPlusData.support",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("24"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.cashInternalName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.cashInternalShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "cashInternalShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.cashInternalShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.cashInternal,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "cashInternal",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.cashInternal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("25"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.iconPlusData.walletName))]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.walletShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "walletShow", $$v)
                                },
                                expression: "iconPlusData.walletShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.wallet,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "wallet", $$v)
                                },
                                expression: "iconPlusData.wallet",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("26"),
                        ]),
                        _c("td", [_vm._v("Tin tức")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.newsShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "newsShow", $$v)
                                },
                                expression: "iconPlusData.newsShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.news,
                                callback: function ($$v) {
                                  _vm.$set(_vm.iconPlusData, "news", $$v)
                                },
                                expression: "iconPlusData.news",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("27"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.absentTeacherName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.absentTeacherShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "absentTeacherShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.absentTeacherShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.absentTeacher,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "absentTeacher",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.absentTeacher",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("28"),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.iconPlusData.attendanceTeacherName)
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.attendanceTeacherShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "attendanceTeacherShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.attendanceTeacherShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.attendanceTeacher,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "attendanceTeacher",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.attendanceTeacher",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("29"),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.iconPlusData.salaryTeacherName)),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.salaryTeacherShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "salaryTeacherShow",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.salaryTeacherShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.salaryTeacher,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "salaryTeacher",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.salaryTeacher",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("30"),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.iconPlusData.statisticSalaryTeacherName)
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.statisticSalaryTeacherShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "statisticSalaryTeacherShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.statisticSalaryTeacherShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.statisticSalaryTeacher,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "statisticSalaryTeacher",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.statisticSalaryTeacher",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("31"),
                        ]),
                        _c("td", [_vm._v("Tổng học sinh")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.totalKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "totalKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.totalKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.totalKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "totalKidStatistic",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.totalKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("32"),
                        ]),
                        _c("td", [_vm._v("Đang học")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.studyingKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "studyingKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.studyingKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.studyingKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "studyingKidStatistic",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.studyingKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("33"),
                        ]),
                        _c("td", [_vm._v("Đã điểm danh")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.attendanceKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "attendanceKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.attendanceKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.attendanceKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "attendanceKidStatistic",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.attendanceKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("34"),
                        ]),
                        _c("td", [_vm._v("Chưa điểm danh")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.noAttendanceKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "noAttendanceKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.noAttendanceKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.noAttendanceKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "noAttendanceKidStatistic",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.noAttendanceKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("35"),
                        ]),
                        _c("td", [_vm._v("Đi học")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.goSchoolKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "goSchoolKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.goSchoolKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.goSchoolKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "goSchoolKidStatistic",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.goSchoolKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("36"),
                        ]),
                        _c("td", [_vm._v("Đã điểm danh về")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value:
                                  _vm.iconPlusData.leaveSchoolKidStatisticShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "leaveSchoolKidStatisticShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.leaveSchoolKidStatisticShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.leaveSchoolKidStatistic,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "leaveSchoolKidStatistic",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.leaveSchoolKidStatistic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", { attrs: { align: "center" } }, [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("37"),
                        ]),
                        _c("td", [_vm._v("Dự kiến đi học")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.learnFutureStatusShow,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "learnFutureStatusShow",
                                    $$v
                                  )
                                },
                                expression:
                                  "iconPlusData.learnFutureStatusShow",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.iconPlusData.learnFutureStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.iconPlusData,
                                    "learnFutureStatus",
                                    $$v
                                  )
                                },
                                expression: "iconPlusData.learnFutureStatus",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _c("div", { attrs: { align: "center" } }, [
            _vm._v("Không có dữ liệu"),
          ]),
      _c("br"),
      _c("br"),
      _vm.iconPlusData != null
        ? _c("div", { attrs: { align: "center" } }, [
            _c("div", { attrs: { align: "center" } }, [
              _vm._v("Cấu hình màu nền cho Icon"),
            ]),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "circle-container" }, [
                _c("div", {
                  staticClass: "color-circle",
                  style: { backgroundColor: _vm.currentColor },
                }),
              ]),
              _c("div", { staticClass: "color-slider" }, [
                _c("label", { attrs: { for: "colorSlider" } }, [
                  _vm._v("Thay đổi màu :"),
                ]),
                _c("div", { staticClass: "slider-container" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.colorValue,
                        expression: "colorValue",
                      },
                    ],
                    style: { background: `hsl(${_vm.colorValue}, 100%, 85%)` },
                    attrs: {
                      type: "range",
                      id: "colorSlider",
                      min: "0",
                      max: "500",
                    },
                    domProps: { value: _vm.colorValue },
                    on: {
                      input: _vm.updateColors,
                      __r: function ($event) {
                        _vm.colorValue = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { attrs: { align: "center" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.iconPlusData.backgroundColorIconPlus,
                        expression: "iconPlusData.backgroundColorIconPlus",
                      },
                    ],
                    attrs: { type: "text", readonly: "" },
                    domProps: {
                      value: _vm.iconPlusData.backgroundColorIconPlus,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.iconPlusData,
                          "backgroundColorIconPlus",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("iconPlusData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("iconPlusData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }