<template>
  <el-dialog
      title="Cấu hình App Plus"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="3vh"
  >
    <div v-if="iconPlusData != null" style="display: flex; justify-content: center">
      <div class="table-icon-1">
        <table border class="table-data">
          <thead class="table-header">
          <tr align="center">
            <td class="table-index">STT</td>
            <td style="text-align: center">Chức năng</td>
            <td class="table-action">Hiển thị</td>
            <td class="table-action">Mở khóa</td>
          </tr>
          </thead>
          <tbody>
          <tr align="center">
            <td class="table-index">1</td>
            <td>{{ iconPlusData.employeeName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.employeeShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.employee"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">2</td>
            <td>{{ iconPlusData.kidsName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.kidsShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.kids"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">3</td>
            <td>{{ iconPlusData.messageName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.messageShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.message"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">4</td>
            <td>{{ iconPlusData.medicineName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.medicineShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.medicine"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">5</td>
            <td>{{ iconPlusData.absentName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.absentShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.absent"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">6</td>
            <td>{{ iconPlusData.attendanceName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendanceShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendance"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">7</td>
            <td>{{ iconPlusData.evaluateName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.evaluateShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.evaluate"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">8</td>
            <td>{{ iconPlusData.albumName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.albumShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.album"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">9</td>
            <td>{{ iconPlusData.healthName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.healthShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.health"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">10</td>
            <td>{{ iconPlusData.learnName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.learnShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.learn"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">11</td>
            <td>{{ iconPlusData.menuName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.menuShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.menu"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">12</td>
            <td>{{ iconPlusData.videoName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.videoShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.video"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">13</td>
            <td>{{ iconPlusData.cameraName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.cameraShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.camera"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">14</td>
            <td>{{ iconPlusData.birthdayName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.birthdayShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.birthday"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">15</td>
            <td>{{ iconPlusData.studentFeesNameNew }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studentFeesShowNew"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studentFeesNew"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">16</td>
            <td>{{ iconPlusData.studentFeesName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studentFeesShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studentFees"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">17</td>
            <td>{{ iconPlusData.salaryName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.salaryShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.salary"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">18</td>
            <td>{{ iconPlusData.utilityName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.utilityShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.utility"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">19</td>
            <td>{{ iconPlusData.facebookName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.facebookShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.facebook"></el-checkbox>
            </td>
          </tr>

          <tr align="center">
            <td class="table-index">20</td>
            <td>{{ iconPlusData.feedbackName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.feedbackShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.feedback"></el-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-icon-2">
        <table border class="table-data">
          <thead class="table-header">
          <tr align="center">
            <td class="table-index">STT</td>
            <td style="text-align: center">Chức năng</td>
            <td class="table-action">Hiển thị</td>
            <td class="table-action">Mở khóa</td>
          </tr>
          </thead>
          <tbody>
          <tr align="center">
            <td class="table-index">21</td>
            <td>{{ iconPlusData.smsAppHistoryName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.smsAppHistoryShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.smsAppHistory"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">22</td>
            <td>{{ iconPlusData.notifyName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.notifyShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.notify"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">23</td>
            <td>{{ iconPlusData.supportName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.supportShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.support"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">24</td>
            <td>{{ iconPlusData.cashInternalName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.cashInternalShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.cashInternal"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">25</td>
            <td>{{ iconPlusData.walletName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.walletShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.wallet"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">26</td>
            <td>Tin tức</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.newsShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.news"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">27</td>
            <td>{{ iconPlusData.absentTeacherName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.absentTeacherShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.absentTeacher"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">28</td>
            <td>{{ iconPlusData.attendanceTeacherName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendanceTeacherShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendanceTeacher"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">29</td>
            <td>{{ iconPlusData.salaryTeacherName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.salaryTeacherShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.salaryTeacher"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">30</td>
            <td>{{ iconPlusData.statisticSalaryTeacherName }}</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.statisticSalaryTeacherShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.statisticSalaryTeacher"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">31</td>
            <td>Tổng học sinh</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.totalKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.totalKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">32</td>
            <td>Đang học</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studyingKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.studyingKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">33</td>
            <td>Đã điểm danh</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendanceKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.attendanceKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">34</td>
            <td>Chưa điểm danh</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.noAttendanceKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.noAttendanceKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">35</td>
            <td>Đi học</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.goSchoolKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.goSchoolKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">36</td>
            <td>Đã điểm danh về</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.leaveSchoolKidStatisticShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.leaveSchoolKidStatistic"></el-checkbox>
            </td>
          </tr>
          <tr align="center">
            <td class="table-index">37</td>
            <td>Dự kiến đi học</td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.learnFutureStatusShow"></el-checkbox>
            </td>
            <td class="table-action">
              <el-checkbox v-model="iconPlusData.learnFutureStatus"></el-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else align="center">Không có dữ liệu</div>
    <br>
    <br>
    <div v-if="iconPlusData != null" align="center">
      <div align="center">Cấu hình màu nền cho Icon</div>
      <div class="container">
        <div class="circle-container">
          <div class="color-circle" :style="{ backgroundColor: currentColor }"></div>
        </div>
        <div class="color-slider">
          <label for="colorSlider">Thay đổi màu :</label>
          <div class="slider-container">
            <input
                type="range"
                id="colorSlider"
                v-model="colorValue"
                min="0"
                max="500"
                :style="{ background: `hsl(${colorValue}, 100%, 85%)` }"
                @input="updateColors"
            />
          </div>
          <div align="center">
            <input
                type="text"
                readonly
                v-model="iconPlusData.backgroundColorIconPlus"
            />
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" size="medium" @click="closeDialogByButton('iconPlusData')">
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm('iconPlusData')"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";

export default {
  props: {
    dialogVisible: null,
  },
  computed: {},
  data() {
    return {
      iconPlusData: {},
      loadingButton: false,
      id: "",
      colorValue: 0,
      currentColor: `hsl(0, 100%, 85%)`,
    };
  },
  methods: {
    //reset when click x
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs["iconPlusData"].resetFields();
      }, 300);
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.colorValue = 0;
      this.currentColor = `hsl(0, 100%, 85%)`;
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs[fromData].resetFields();
      }, 300);
    },
    resetFormData() {
      this.$refs["iconPlusData"].resetFields();
    },
    submitForm(fromData) {
      this.loadingButton = true;
      SystemConfigService.updateSchoolIconPlus(this.id, this.iconPlusData)
          .then((response) => {
            this.$message({
              message: response.data.message,
              type: "success",
            });
            setTimeout(() => {
              this.closeDialogByButton(fromData);
            }, 500);
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingButton = false;
            }, 500);
          });
    },
    getDataInitial(id) {
      this.id = id;
      SystemConfigService.findSchoolIconPlus(id)
          .then((resp) => {
            this.iconPlusData = resp.data.data;
            let values = this.iconPlusData.backgroundColorIconPlus.split(',').map(value => parseFloat(value));
            this.colorValue = values[0];
            this.currentColor = `hsl(${this.colorValue}, 100%, 85%)`;

          })
          .catch((err) => {
            console.log(err);
            this.iconPlusData = null;
          });
    },
    updateColors() {
      this.currentColor = `hsl(${this.colorValue}, 100%, 85%)`;
      this.iconPlusData.backgroundColorIconPlus = `${this.colorValue},100,85`;
    },
    mounted() {
      this.updateColors();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-icon-1 {
  margin-left: 50px;
  margin-right: 40px;
}

.table-icon-2 {
  margin-right: 50px;
  margin-left: 40px;
}

.over-table {
  width: 97%;
  margin: 0 auto;
}

.table-data {
  border-collapse: collapse;
  border: 1px solid #ebeef5;
}

.wrapper-table {
  height: 600px;
  overflow-y: scroll;
  border-bottom: 1px solid #ebeef5;
}

thead {
  background: #78a5e7;
  color: white;
  font-weight: bold;
}

tbody {
  color: #606266;
  font-size: 14px;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
}

td {
  padding: 10px;
}

.table-index {
  width: 30px;
}

.table-item-name {
  width: 200px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.circle-container {
  margin-right: 2rem;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slider-container {
  display: flex;
  align-items: center;
  width: 200px;
  position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  border-radius: 15px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}
</style>
