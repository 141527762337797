var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cấu hình nhận thông báo",
        visible: _vm.dialogVisible,
        width: "1100px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c("div", { staticClass: "table-icon-1" }, [
            _c("span", { staticStyle: { "margin-left": "60px" } }, [
              _vm._v("App Parent"),
            ]),
            _c("table", { staticClass: "table-data", attrs: { border: "" } }, [
              _c("thead", { staticClass: "table-header" }, [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("STT")]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Chức năng"),
                  ]),
                  _c("td", { staticClass: "table-action" }, [
                    _vm._v("Sử dụng"),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                  _c("td", [_vm._v("Thông báo")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.notify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "notify",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.notify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                  _c("td", [_vm._v("Lời nhắn")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.message,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "message",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.message",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                  _c("td", [_vm._v("Dặn thuốc")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.medicine,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "medicine",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.medicine",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                  _c("td", [_vm._v("Xin nghỉ")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.absent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "absent",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.absent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                  _c("td", [_vm._v("Album")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.album,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "album",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.album",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                  _c("td", [_vm._v("Nhận xét")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.evaluate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "evaluate",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.evaluate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                  _c("td", [_vm._v("Điểm danh")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.attendance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "attendance",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.attendance",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                  _c("td", [_vm._v("Góp ý")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.feedback,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "feedback",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.feedback",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                  _c("td", [_vm._v("Sinh nhật")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.birthday,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "birthday",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("10")]),
                  _c("td", [_vm._v("Thông báo nạp/rút ví")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.wallet,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "wallet",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.wallet",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("11")]),
                  _c("td", [_vm._v("Thông báo học phí")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.orderShow,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "orderShow",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.orderShow",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("12")]),
                  _c("td", [_vm._v("Thông báo thanh toán")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyParent.orderPayment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "orderPayment",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.orderPayment",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("13")]),
                  _c("td", [_vm._v("Thông báo hóa đơn")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyParent.orderNotify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "orderNotify",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyParent.orderNotify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("14")]),
                  _c("td", [_vm._v("Tin tức")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyParent.news,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyParent,
                              "news",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyParent.news",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-icon-2" }, [
            _c("span", { staticStyle: { "margin-left": "60px" } }, [
              _vm._v("App Teacher"),
            ]),
            _c("table", { staticClass: "table-data", attrs: { border: "" } }, [
              _c("thead", { staticClass: "table-header" }, [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("STT")]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Chức năng"),
                  ]),
                  _c("td", { staticClass: "table-action" }, [
                    _vm._v("Sử dụng"),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                  _c("td", [_vm._v("Thông báo")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.notify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "notify",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyTeacher.notify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                  _c("td", [_vm._v("Lời nhắn")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.message,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "message",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.message",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                  _c("td", [_vm._v("Dặn thuốc")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.medicine,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "medicine",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.medicine",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                  _c("td", [_vm._v("Xin nghỉ")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.absent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "absent",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyTeacher.absent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                  _c("td", [_vm._v("Nhận xét")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.evaluate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "evaluate",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.evaluate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                  _c("td", [_vm._v("Góp ý")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.feedback,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "feedback",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.feedback",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                  _c("td", [_vm._v("Sinh nhật")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.birthday,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "birthday",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                  _c("td", [_vm._v("Thông báo học phí")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.orderShow,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "orderShow",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.orderShow",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                  _c("td", [_vm._v("Thông báo thanh toán")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyTeacher.orderPayment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "orderPayment",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.orderPayment",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("10")]),
                  _c("td", [_vm._v("Thông báo hóa đơn")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyTeacher.orderNotify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "orderNotify",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyTeacher.orderNotify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("11")]),
                  _c("td", [_vm._v("Tin tức")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyTeacher.news,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyTeacher,
                              "news",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyTeacher.news",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-icon-3" }, [
            _c("span", { staticStyle: { "margin-left": "60px" } }, [
              _vm._v("App Plus"),
            ]),
            _c("table", { staticClass: "table-data", attrs: { border: "" } }, [
              _c("thead", { staticClass: "table-header" }, [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("STT")]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Chức năng"),
                  ]),
                  _c("td", { staticClass: "table-action" }, [
                    _vm._v("Sử dụng"),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                  _c("td", [_vm._v("Thông báo")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.notify,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "notify",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.notify",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                  _c("td", [_vm._v("Lời nhắn")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.message,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "message",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.message",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                  _c("td", [_vm._v("Dặn thuốc")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.medicine,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "medicine",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.medicine",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                  _c("td", [_vm._v("Xin nghỉ")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.absent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "absent",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.absent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                  _c("td", [_vm._v("Nhận xét")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.evaluate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "evaluate",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.evaluate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("6")]),
                  _c("td", [_vm._v("Góp ý")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.feedback,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "feedback",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.feedback",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("7")]),
                  _c("td", [_vm._v("Tin tức")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.news,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "news",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.news",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("8")]),
                  _c("td", [_vm._v("Duyệt album")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyPlus.albumApproved,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "albumApproved",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyPlus.albumApproved",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("9")]),
                  _c("td", [_vm._v("Album mới")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.albumNew,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "albumNew",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.albumNew",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("10")]),
                  _c("td", [_vm._v("Quản lý học phí")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.feesNew,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "feesNew",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.feesNew",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("11")]),
                  _c("td", [_vm._v("Học phí")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.fees,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "fees",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.fees",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("12")]),
                  _c("td", [_vm._v("Công lương")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.salary,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "salary",
                              $$v
                            )
                          },
                          expression: "responseData.configNotifyPlus.salary",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("13")]),
                  _c("td", [_vm._v("Thu chi nội bộ")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.responseData.configNotifyPlus.cashInternal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "cashInternal",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyPlus.cashInternal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", { attrs: { align: "center" } }, [
                  _c("td", { staticClass: "table-index" }, [_vm._v("14")]),
                  _c("td", [_vm._v("Xin nghỉ nhân sự")]),
                  _c(
                    "td",
                    { staticClass: "table-action" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value:
                            _vm.responseData.configNotifyPlus.absentTeacher,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.responseData.configNotifyPlus,
                              "absentTeacher",
                              $$v
                            )
                          },
                          expression:
                            "responseData.configNotifyPlus.absentTeacher",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }