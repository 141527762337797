<template>
  <el-dialog
      title="Thông tin cấu hình nhà trường"
      :visible.sync="dialogVisible"
      width="1100px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="2vh"
  >
    <div
        v-if="systemConfigSchoolData != null"
        class="wrapper-table"
    >
      <table border class="table-data">
        <thead>
          <tr>
            <td style="width: 30px">STT</td>
            <td style="width: 800px">Nội dung thiết lập</td>
            <td class="table-action" style="width: 300px">Thiết lập</td>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td class="table-index">1</td>
          <td>Có cần duyệt Album ảnh hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="systemConfigSchoolData.schoolConfigInSysResponse.album"
            >
              <el-radio :label="false">Có</el-radio>
              <el-radio :label="true">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">2</td>
          <td>Có cần duyệt đánh giá nhận xét hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.evaluate
                "
            >
              <el-radio :label="false">Có</el-radio>
              <el-radio :label="true">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">3</td>
          <td>Có cho phép sửa đánh giá khi đã duyệt hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.editAproved
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">4</td>
          <td>Có hiển thị chức năng đánh giá ngày hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.evaluateDate
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">5</td>
          <td>Có hiển thị chức năng đánh giá tuần hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.evaluateWeek
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">6</td>
          <td>Có hiển thị chức năng đánh giá tháng hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.evaluateMonth
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">7</td>
          <td>Có hiển thị chức năng đánh giá định kỳ hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .evaluatePeriod
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">8</td>
          <td>Có gửi thông báo điểm danh đến hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .approvedAttendanceArrive
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">9</td>
          <td>Giới hạn thời gian gửi điểm danh đến trước mấy giờ?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeAttendanceArrive
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">10</td>
          <td>Có gửi thông báo điểm danh về hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .approvedAttendanceLeave
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">11</td>
          <td>Giới hạn thời gian gửi điểm danh về trước mấy giờ?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeAttendanceLeave
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">12</td>
          <td>Cho phép điểm danh lại với học sinh mấy ngày trước?</td>
          <td class="table-action">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.againAttendance
                "
                placeholder="Nhập số ngày"
            ></el-input>
          </td>
        </tr>

        <tr>
          <td class="table-index">13</td>
          <td>
            Gửi đơn xin nghỉ phải trước ngày đầu tiên muốn nghỉ số ngày là?
          </td>
          <td class="table-action">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.dateAbsent
                "
                placeholder="Nhập số ngày"
            ></el-input>
          </td>
        </tr>

        <tr>
          <td class="table-index">14</td>
          <td>Thời gian xin nghỉ phải trước mấy giờ?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeAbsent
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">15</td>
          <td>Mặc định có học sáng thứ 7 hàng tuần không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .morningSaturday
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">16</td>
          <td>Mặc định có học chiều thứ 7 hàng tuần không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .afternoonSaturday
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">17</td>
          <td>Mặc định có học tối thứ 7 hàng tuần không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .eveningSaturday
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">18</td>
          <td>Mặc định có học chủ nhật hàng tuần không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.sunday
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">19</td>
          <td>Hiển thị điểm danh đi học buổi sáng không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.morningAttendanceArrive
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">20</td>
          <td>Hiển thị điểm danh đi học buổi chiều không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.afternoonAttendanceArrive
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">21</td>
          <td>Hiển thị điểm danh đi học buổi tối không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.eveningAttendanceArrive
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">22</td>
          <td>Hiển thị điểm danh ăn bữa sáng không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.morningEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">23</td>
          <td>Hiển thị điểm danh ăn bữa phụ sáng không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.secondMorningEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">24</td>
          <td>Hiển thị điểm danh ăn bữa trưa không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.lunchEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">25</td>
          <td>Hiển thị điểm danh ăn bữa chiều không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.afternoonEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">26</td>
          <td>Hiển thị điểm danh ăn bữa phụ chiều không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.secondAfternoonEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">27</td>
          <td>Hiển thị điểm danh ăn bữa tối không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.eveningEat
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">28</td>
          <td>Có cần duyệt các thông báo gửi đi hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.appSendApproved
                "
            >
              <el-radio :label="false">Có</el-radio>
              <el-radio :label="true">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">29</td>
          <td>Có cần duyệt nội dung thời khóa biểu hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.approvedSchedule
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">30</td>
          <td>Có cần duyệt nội dung thực đơn hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.approvedMenu
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">31</td>
          <td>
            Cho phép hiển thị số điện thoại của Phụ huynh với App Giáo viên?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.parentPhone
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">32</td>
          <td>
            Cho phép Giáo viên xem thông tin chi tiết của Phụ huynh hay không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.parentInfo
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">33</td>
          <td>
            Cho phép hiển thị số điện thoại của Giáo viên với App Phụ huynh?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.employeePhone
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">34</td>
          <td>
            Cho phép Phụ huynh xem thông tin chi tiết của Giáo viên không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.employeeInfo
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">35</td>
          <td>
            Cho phép Phụ huynh xem lại lịch sử học của con khi đã chuyển
            trường hay không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.historyViewParent
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">36</td>
          <td>
            Cho phép Nhân viên xem lại lịch sử làm việc khi đã nghỉ làm ở
            trường hày không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.historyViewTeacher
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">37</td>
          <td>
            Giáo viên có được xem chi tiết các khoản thu của học sinh lớp mình
            hay không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.feesInfo
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">38</td>
          <td>
            Giáo viên có được xem số tiền học phí của học sinh hay không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.feesNumber
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">39</td>
          <td>Thiết lập giờ đến quy định của Nhà trường với học sinh?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeArriveKid
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">40</td>
          <td>Thiết lập giờ về quy định của Nhà trường với học sinh?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timePickupKid
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">41</td>
          <td>Hiển thị thêm mẫu nhận xét của hệ thống?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.showEvaluateSys
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">42</td>
          <td>Hiển thị thêm mẫu báo đến, báo về của hệ thống?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.showAttentendanceSys
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">43</td>
          <td>Hiển thị thêm mẫu lời chúc của hệ thống?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.showWishSys
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">44</td>
          <td>Thiết lập giờ đi làm buổi sáng của Nhân viên Nhà trường?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeArriveEmployee
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">45</td>
          <td>Thiết lập giờ về của Nhân viên Nhà trường?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.timeLeaveEmployee
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr>
          <td class="table-index">46</td>
          <td>Có tự động duyệt các phiếu thu không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.receiptNote
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">47</td>
          <td>Có tự động duyệt các phiếu chi không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.paymentNote
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">48</td>
          <td>
            Có sinh tự động hóa đơn công lương của Nhân viên cho tháng tiếp
            theo không (sinh tự động vào ngày đầu tháng)?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoBillSalaryEmployee
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">49</td>
          <td>
            Có sinh tự động các khoản công lương cho Nhân viên theo các khoản
            mặc định?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoSignSalaryEmployee
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">50</td>
          <td>
            Thiết lập ngày của tháng sẽ sinh tự động các khoản công lương cho
            Nhân sự?
          </td>
          <td class="table-action">
            <el-input-number
                :disabled="
                  !systemConfigSchoolData.schoolConfigInSysResponse
                    .autoSignSalaryEmployee
                "
                :min="1"
                :max="31"
                size="mini"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoNextmonthSalaryDate
                "
            ></el-input-number>
          </td>
        </tr>

        <tr>
          <td class="table-index">51</td>
          <td>Có tự động duyệt các khoản lương hàng tháng không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoApprovedSalary
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">52</td>
          <td>Có tự động khóa các khoản lương khi đã thanh toán đủ không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoLockSalarySuccess
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">53</td>
          <td>
            Có tự động hiển thị hóa đơn lương của cả tháng khi hóa đơn đã được
            sinh ra không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoShowBillOfMonth
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">54</td>
          <td>
            Có sinh tự động hóa đơn học phí của học sinh cho tháng tiếp theo
            không (sinh tự động vào ngày đầu tháng)?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoFeesApprovedKids
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">55</td>
          <td>
            Có sinh tự động các khoản thu với học sinh theo các khoản thu đã
            đăng ký?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoGenerateFeesKids
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">56</td>
          <td>
            Thiết lập ngày của tháng sẽ sinh tự động các khoản thu cho học
            sinh?
          </td>
          <td class="table-action">
            <el-input-number
                :disabled="
                  !systemConfigSchoolData.schoolConfigInSysResponse
                    .autoGenerateFeesKids
                "
                :min="1"
                :max="31"
                size="mini"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoNexMonthFeesDate
                "
            ></el-input-number>
          </td>
        </tr>

        <tr>
          <td class="table-index">57</td>
          <td>
            Có tự động duyệt các khoản thu của học sinh hàng tháng không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoApprovedFeesMonthKids
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">58</td>
          <td>
            Có tự động khóa các khoản thu khi Phụ huynh đã thanh toán đủ
            không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoLockFeesSuccessKids
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">59</td>
          <td>
            Có tự động hiển thị hóa đơn học phí của cả tháng khi hóa đơn đã
            được sinh ra không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesMonth
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">60</td>
          <td>
            Có hiển thị thông tin các khoản thu dự kiến cho Phụ huynh học sinh
            không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesFutureKids
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">61</td>
          <td>Có cho phép Phụ huynh truy cập ví hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.showWalletParent
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">62</td>
          <td>
            Có hiển thị thông tin khoản thu dự kiến của học sinh cho Giáo viên
            không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.autoShowFeesFutureKidsForTeacher
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">63</td>
          <td>Hiển thị nút xóa Nhân sự, học sinh hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.deleteStatus
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">64</td>
          <td>Mã xử lý trùng tài khoản</td>
          <td class="table-action">
            <el-input
                size="mini"
                :maxlength="6"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.verifyCode
                "
            >
            </el-input>
          </td>
        </tr>

        <tr>
          <td class="table-index">65</td>
          <td>
            Có cho phép ra trường, nghỉ học khi chưa hoàn thành học phí không?
          </td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.checkCompleteFeesStatus
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">66</td>
          <td>Có gửi thông báo khi Nhà trường rút tiền từ ví không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.notifyWalletOutStatus
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">67</td>
          <td>Hiện OneCam Plus trong danh sách Camera Nhà trường?</td>
          <td class="table-action">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse.showOnecamPlus
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">68</td>
          <td>Số lượng ảnh tối đa khi đăng Album?</td>
          <td class="table-action">
            <el-input
                size="mini"
                v-model="
                  systemConfigSchoolData.schoolConfigInSysResponse
                    .albumMaxNumber
                "
            >
            </el-input>
          </td>
        </tr>

        <tr>
          <td class="table-index">70</td>
          <td>Áp dụng tự động điền điểm danh ăn sáng theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.breakfastAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">71</td>
          <td>Áp dụng tự động điền điểm danh ăn phụ sáng theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.secondBreakfastAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">72</td>
          <td>Áp dụng tự động điền điểm danh ăn trưa theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.lunchAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">73</td>
          <td>Áp dụng tự động điền điểm danh ăn chiều theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.afternoonAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">74</td>
          <td>Áp dụng tự động điền điểm danh ăn phụ chiều theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.secondAfternoonAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">75</td>
          <td>Áp dụng tự động điền điểm danh ăn tối theo đi học?</td>
          <td class="table-action">
            <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.dinnerAuto">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">76</td>
          <td>Có tự động tạo điểm danh khi học sinh xin nghỉ học quá thời hạn quy định hay không?</td>
          <td class="table-action">
            <el-radio-group
                v-model="systemConfigSchoolData.schoolConfigInSysResponse.attendanceCreateLetter"
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="table-index">77</td>
          <td>Chọn trạng thái điểm danh mặc định khi xin nghỉ quá thời hạn?</td>
          <td class="table-action">
            <el-radio-group
                v-model="systemConfigSchoolData.schoolConfigInSysResponse.absentYesNoLetter"
            >
              <el-radio :label="true">Có phép</el-radio>
              <el-radio :label="false">Không phép</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <!-- ben co dinh -->
        <tr>
          <td class="table-index disable-row">68</td>
          <td class="disable-row">Thời gian gửi lời chúc mừng mặc định?</td>
          <td class="table-action">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.timeSendCelebrate
                "
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">69</td>
          <td>Chất lượng ảnh theo %?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.qualityPicture
                "
                placeholder="Nhập khoảng"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">70</td>
          <td>Chất lượng ảnh theo kích thước?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="systemConfigSchoolData.sysConfigResponse.widthPicture"
                placeholder="Nhập khoảng"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">71</td>
          <td>Chèn tiêu đề vào nội dung tin nhắn?</td>
          <td class="table-action" style="background: white">
            <el-radio-group
                v-model="systemConfigSchoolData.sysConfigResponse.showTitleSms"
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">72</td>
          <td>Nội dung tiêu đề mặc định trong tin nhắn SMS?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.titleContentSms
                "
                placeholder="Nhập tiêu đề"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">73</td>
          <td>
            Hiện Phụ huynh đã đọc hay chưa đọc với App Giáo viên và Plus
            (Thông báo, nhận xét)?
          </td>
          <td class="table-action" style="background: white">
            <el-radio-group
                v-model="systemConfigSchoolData.sysConfigResponse.appSendParent"
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">74</td>
          <td>
            Hiện Giáo viên và Nhà trường đã đọc hay chưa đọc với App Phụ huynh
            (Lời nhắn, dặn thuốc, xin nghỉ, góp ý)?
          </td>
          <td class="table-action" style="background: white">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.sysConfigResponse.appSendTeacher
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">75</td>
          <td>Số lần nhắc lại khi có nội dung mới với App Phụ huynh?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.numberRemindParent
                "
                placeholder="Nhập số lần"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">76</td>
          <td>Khoảng thời gian nhắc giữa 2 lần của App Phụ huynh (phút)?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.jumpTimeParent
                "
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">77</td>
          <td>Số lần nhắc lại khi có nội dung mới với App Giáo viên?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.numberRemindTeacher
                "
                placeholder="Nhập số lần"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">78</td>
          <td>Khoảng thời gian nhắc giữa 2 lần của App Giáo viên (phút)?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.jumpTimeTeacher
                "
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">79</td>
          <td>Số lần nhắc lại khi có nội dung mới với App Plus?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.numberRemindPlus
                "
                placeholder="Nhập số lần"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">80</td>
          <td>Khoảng thời gian nhắc giữa 2 lần của App Plus (phút)?</td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="systemConfigSchoolData.sysConfigResponse.jumpTimePlus"
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">81</td>
          <td>
            Thời gian bắt đầu nhắc lần đầu có thông tin mới (mặc định là 8h30)?
          </td>
          <td class="table-action" style="background: white">
            <el-time-picker
                style="float: left; width: 300px"
                class="input-data"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="systemConfigSchoolData.sysConfigResponse.timeFrom"
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">82</td>
          <td>Thời gian cuối cùng có thể nhắc lần đầu (mặc định là 8h30)?</td>
          <td class="table-action" style="background: white">
            <el-time-picker
                class="input-data"
                style="float: left; width: 300px"
                size="mini"
                :clearable="false"
                type="time"
                value-format="HH:mm"
                format="HH:mm"
                v-model="systemConfigSchoolData.sysConfigResponse.timeTo"
                :picker-options="{
                  selectableRange: '06:00:00 - 21:00:00',
                }"
                placeholder="Chọn"
            ></el-time-picker>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">83</td>
          <td>
            Số ngày quy định sau khi học sinh sang trường mới học sẽ báo Nhà
            trường, nếu học sinh còn ở trạng thái đi học?
          </td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.numberOutSchool
                "
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">84</td>
          <td>
            Số lần sẽ gửi thông báo cho Nhà trường với trường hợp sang trường
            mới học mà chưa nghỉ học tại trường?
          </td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="systemConfigSchoolData.sysConfigResponse.numberNotify"
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">85</td>
          <td>
            Khoảng thời gian gửi thông báo cho nhà trường khi học sinh học
            trường mới (đơn vị là ngày/1 lần)?
          </td>
          <td class="table-action" style="background: white">
            <el-input
                class="input-data"
                size="mini"
                v-model="systemConfigSchoolData.sysConfigResponse.jumpNumberOut"
                placeholder="Nhập số phút"
            ></el-input>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">86</td>
          <td>Có tự động xóa tài khoản khi không còn đối tượng con không?</td>
          <td class="table-action" style="background: white">
            <el-radio-group
                v-model="
                  systemConfigSchoolData.sysConfigResponse.deleteAccountStatus
                "
            >
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr class="disable-row">
          <td class="table-index">87</td>
          <td>Thời gian xóa tự động tài khoản là sau mấy ngày?</td>
          <td class="table-action" style="background: white">
            <el-input-number
                class="input-data"
                size="mini"
                v-model="
                  systemConfigSchoolData.sysConfigResponse.deleteAccountDate
                "
                placeholder="Nhập số ngày"
            ></el-input-number>
          </td>
        </tr>

        <tr>
          <td class="table-index">88</td>
          <td>Thiết lập thời gian đón muộn</td>
          <td class="table-action">
            <table class="center-table">
              <tr>
                <td>
                  <el-radio-group
                      v-model="systemConfigSchoolData.schoolConfigInSysResponse.statusBlock"
                  >
                    <el-radio :label="true">Block</el-radio>
                    <el-radio :label="false">Ngày</el-radio>
                  </el-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="margin-bottom: 10px">Nhập số phút</span>
                  <el-input-number
                      v-model="systemConfigSchoolData.schoolConfigInSysResponse.blockLeaveKid"
                      style="width: 200px"
                      :min="0"
                      :step="1"
                      placeholder="Nhập số phút">
                    >
                  </el-input-number>
                </td>
              </tr>
              <tr>
                <td>
                  <el-radio-group v-model="systemConfigSchoolData.schoolConfigInSysResponse.statusUpDown">
                    <el-radio :label="true">Làm tròn lên</el-radio>
                    <el-radio :label="false">Làm tròn xuống</el-radio>
                  </el-radio-group>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td class="table-index">89</td>
          <td>Cấu hình thứ tự hiển thị tin tức màn Home trên App?</td>
          <td>
            <div class="sttShowTabHomeTable">
              <table>
                <tr>
                  <td style="text-align: center">Tin tức</td>
                  <td style="text-align: center">Facebook</td>
                  <td style="text-align: center">Video</td>
                  <td style="text-align: center">Website trường</td>
                  <td style="text-align: center">Tiktok</td>
                  <td style="text-align: center">Onekids Page</td>
                </tr>
                <tr>
                  <td style="text-align: center">
                    <el-checkbox
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowNews"
                        :disabled="true"
                    ></el-checkbox>
                  </td>
                  <td style="text-align: center">
                    <el-checkbox
                        @change="changeShowFacebook"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowFacebook"
                    ></el-checkbox>
                  </td>
                  <td style="text-align: center">
                    <el-checkbox
                        @change="changeShowVideo"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowVideo"
                    ></el-checkbox>
                  </td>
                  <td style="text-align: center">
                    <el-checkbox
                        @change="changeShowWebsiteSchool"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteSchool"
                    ></el-checkbox>
                  </td>
                  <td style="text-align: center">
                    <el-checkbox
                        @change="changeShowTiktok"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok"
                    ></el-checkbox>
                  </td>
                  <td style="text-align: center">
                    <el-checkbox
                        @change="changeShowOneKidsPage"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage"
                    ></el-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>
                    <el-select
                        class="input-common"
                        @change="changeNumberShowNews"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews"
                        placeholder="Chọn thứ tự hiển thị tin tức"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>


                  <td>
                    <el-select
                        class="input-common"
                        @change="changeNumberShowFacebook"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook"
                        placeholder="Chọn thứ tự hiển thị Facebook"
                        :disabled="!systemConfigSchoolData.schoolConfigInSysResponse.activeShowFacebook"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>

                  <td>
                    <el-select
                        class="input-common"
                        @change="changeNumberShowVideo"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo"
                        placeholder="Chọn thứ tự hiển thị Video"
                        :disabled="!systemConfigSchoolData.schoolConfigInSysResponse.activeShowVideo"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>

                  <td>
                    <el-select
                        class="input-common"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool"
                        @change="changeNumberShowWebsiteSchool"
                        placeholder="Chọn thứ tự hiển thị trang web của trường"
                        :disabled="!systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteSchool"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>

                  <td>
                    <el-select
                        class="input-common"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok"
                        @change="changeNumberShowTiktok"
                        placeholder="Chọn thứ tự hiển thị Tiktok"
                        :disabled="!systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>

                  <td>
                    <el-select
                        class="input-common"
                        @change="changeNumberShowOneKidsPage"
                        v-model="systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage"
                        placeholder="Chọn thứ tự hiển thị trang web của Onekids"
                        :disabled="!systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage"
                    >
                      <el-option
                          v-for="item in numberSelect"
                          :key="item"
                          :disabled="item === systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage"
                          :value="item"
                          :label="`${item}`"
                      ></el-option>
                    </el-select>
                  </td>
                </tr>
              </table>
            </div>

          </td>
        </tr>

        <tr>
      <td class="table-index">90</td>
      <td>Có tự động xóa dữ liệu điểm danh, nhận xét trùng hay không?</td>
      <td class="table-action">
        <el-radio-group
            v-model="systemConfigSchoolData.schoolConfigInSysResponse.attendanceDuplicate"
        >
          <el-radio :label="true">Có</el-radio>
          <el-radio :label="false">Không</el-radio>
        </el-radio-group>
      </td>
    </tr>

        </tbody>
      </table>
    </div>
    <div v-else style="text-align: center">Không có dữ liệu</div>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="danger"
          size="medium"
          @click="closeDialogByButton('systemConfigSchoolData')"
      >
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm('systemConfigSchoolData')"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import {optionSerialNumberShowNewsHomeMap} from "@/constant/listCommon";

export default {
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      systemConfigSchoolData: {
        idSchool:'',
        schoolConfigInSysResponse: {
          attendanceDuplicate: undefined,
          activeShowWebsiteSchool: undefined,
          activeShowNews: undefined,
          statusUpDown: undefined,
          blockLeaveKid: undefined,
          statusBlock: undefined,
          absentYesNoLetter: undefined,
          attendanceCreateLetter: undefined,
          dinnerAuto: undefined,
          secondAfternoonAuto: undefined,
          afternoonAuto: undefined,
          lunchAuto: undefined,
          secondBreakfastAuto: undefined,
          breakfastAuto: undefined,
          showOnecamPlus: undefined,
          notifyWalletOutStatus: undefined,
          checkCompleteFeesStatus: undefined,
          autoShowFeesFutureKidsForTeacher: undefined,
          showWalletParent: undefined,
          autoShowFeesFutureKids: undefined,
          autoShowFeesMonth: undefined,
          autoLockFeesSuccessKids: undefined,
          autoApprovedFeesMonthKids: undefined,
          autoNexMonthFeesDate: undefined,
          autoGenerateFeesKids: undefined,
          approvedAttendanceLeave: undefined,
          timeAttendanceLeave: undefined,
          againAttendance: undefined,
          timeAbsent: undefined,
          morningAttendanceArrive: undefined,
          afternoonAttendanceArrive: undefined,
          eveningAttendanceArrive: undefined,
          morningEat: undefined,
          secondMorningEat: undefined,
          lunchEat: undefined,
          afternoonEat: undefined,
          secondAfternoonEat: undefined,
          eveningEat: undefined,
          appSendApproved: undefined,
          approvedSchedule: undefined,
          approvedMenu: undefined,
          parentPhone: undefined,
          parentInfo: undefined,
          employeePhone: undefined,
          employeeInfo: undefined,
          historyViewParent: undefined,
          historyViewTeacher: undefined,
          feesInfo: undefined,
          feesNumber: undefined,
          timePickupKid: undefined,
          showEvaluateSys: undefined,
          showAttentendanceSys: undefined,
          showWishSys: undefined,
          timeArriveEmployee: undefined,
          timeLeaveEmployee: undefined,
          autoBillSalaryEmployee: undefined,
          autoSignSalaryEmployee: undefined,
          autoNextmonthSalaryDate: undefined,
          autoApprovedSalary: undefined,
          autoLockSalarySuccess: undefined,
          autoShowBillOfMonth: undefined,
          autoFeesApprovedKids: undefined,
        },
        sysConfigResponse: {
          deleteAccountDate: undefined,
          deleteAccountStatus: undefined,
          jumpNumberOut: undefined,
          numberNotify: undefined,
          numberOutSchool: undefined,
          timeTo: undefined,
          timeFrom: undefined,
          jumpTimePlus: undefined,
          numberRemindPlus: undefined,
          jumpTimeTeacher: undefined,
          numberRemindTeacher: undefined,
          jumpTimeParent: undefined,
          numberRemindParent: undefined,
          appSendTeacher: undefined,
          appSendParent: undefined,
          titleContentSms: undefined,
          showTitleSms: undefined,
          timeSendCelebrate: undefined,
        }
      },
      optionSerialNumberShowNewsHomeMap,
      loadingButton: false,
      numberSelect: 6,
      numberOld:0,
      sttShowNews:0,
      sttShowFacebook:0,
      sttShowVideo:0,
      sttShowWebsiteSchool:0,
      sttShowTiktok:0,
      sttShowOneKidsPage:0,
      maxCountShow: 6
    };
  },
  methods: {
    checkActive() {
      let countActive = 0;
      const activeShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowNews;
      const activeShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowFacebook;
      const activeShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowVideo;
      const activeShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok;
      const activeShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteSchool;
      const activeShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage;

      if (activeShowNews) {
        ++countActive;
      }

      if (activeShowFacebook) {
        ++countActive;
      }

      if (activeShowVideo) {
        ++countActive;
      }

      if (activeShowTiktok) {
        ++countActive;
      }

      if (activeShowWebsiteSchool) {
        ++countActive;
      }

      if (activeShowOneKidsPage) {
        ++countActive;
      }

      return countActive;
    },

    changeShowFacebook(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowFacebook = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowFacebook) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowFacebook) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowFacebook) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowTiktok> indexShowFacebook) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage> indexShowFacebook) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = this.maxCountShow;
        this.sttShowFacebook = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowFacebook;
          this.sttShowNews = indexShowFacebook;
        }

        if (indexShowTiktok === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowFacebook;
          this.sttShowTiktok = indexShowFacebook;
        }

        if (indexShowVideo === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowFacebook;
          this.sttShowVideo = indexShowFacebook;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowFacebook;
          this.sttShowWebsiteSchool = indexShowFacebook;
        }

        if (indexShowOneKidsPage === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowFacebook;
          this.sttShowOneKidsPage = indexShowFacebook;
        }
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = countActive;
        this.sttShowFacebook = countActive;
        ++this.numberSelect;
      }
    },

    changeShowVideo(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowVideo = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowVideo) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowFacebook > indexShowVideo) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowWebsiteSchool > indexShowVideo) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowTiktok> indexShowVideo) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage> indexShowVideo) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = this.maxCountShow;
        this.sttShowVideo = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowVideo;
          this.sttShowNews = indexShowVideo;
        }

        if (indexShowTiktok === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowVideo;
          this.sttShowTiktok = indexShowVideo;
        }

        if (indexShowFacebook === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowVideo;
          this.sttShowFacebook = indexShowVideo;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowVideo;
          this.sttShowWebsiteSchool = indexShowVideo;
        }

        if (indexShowOneKidsPage === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowVideo;
          this.sttShowOneKidsPage = indexShowVideo;
        }
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = countActive;
        this.sttShowVideo = countActive;
        ++this.numberSelect;
      }
    },

    changeShowWebsiteSchool(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteShool = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowWebsiteSchool) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowWebsiteSchool) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowFacebook > indexShowWebsiteSchool) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowTiktok> indexShowWebsiteSchool) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        if(indexShowOneKidsPage> indexShowWebsiteSchool) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = this.maxCountShow;
        this.sttShowWebsiteSchool = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowWebsiteSchool;
          this.sttShowNews = indexShowWebsiteSchool;
        }

        if (indexShowTiktok === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowWebsiteSchool;
          this.sttShowTiktok = indexShowWebsiteSchool;
        }

        if (indexShowFacebook === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowWebsiteSchool;
          this.sttShowFacebook = indexShowWebsiteSchool;
        }

        if (indexShowVideo === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowWebsiteSchool;
          this.sttShowVideo = indexShowWebsiteSchool;
        }

        if (indexShowOneKidsPage === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowWebsiteSchool;
          this.sttShowOneKidsPage = indexShowWebsiteSchool;
        }
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = countActive;
        this.sttShowWebsiteSchool = countActive;
        ++this.numberSelect
      }
    },

    changeShowTiktok(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowTiktok) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowTiktok) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowTiktok) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowFacebook > indexShowTiktok) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowOneKidsPage > indexShowTiktok) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;
          --this.sttShowOneKidsPage;
        }

        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = this.maxCountShow;
        this.sttShowTiktok = this.maxCountShow;
      } else {

        if (indexShowNews === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowTiktok;
          this.sttShowNews = indexShowTiktok;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowTiktok;
          this.sttShowWebsiteSchool = indexShowTiktok;
        }

        if (indexShowFacebook === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowTiktok;
          this.sttShowFacebook = indexShowTiktok;
        }

        if (indexShowVideo === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowTiktok;
          this.sttShowVideo = indexShowTiktok;
        }

        if (indexShowOneKidsPage === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowTiktok;
          this.sttShowOneKidsPage = indexShowTiktok;
        }
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = countActive;
        this.sttShowTiktok = countActive;
        ++this.numberSelect;
      }
    },

    changeShowOneKidsPage(value) {
      const countActive = this.checkActive();
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage = value;

      if(!value) {
        --this.numberSelect;

        if(indexShowNews > indexShowOneKidsPage) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
          --this.sttShowNews;
        }

        if(indexShowVideo > indexShowOneKidsPage) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
          --this.sttShowVideo;
        }

        if(indexShowWebsiteSchool > indexShowOneKidsPage) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
          --this.sttShowWebsiteSchool;
        }

        if(indexShowFacebook > indexShowOneKidsPage) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
          --this.sttShowFacebook;
        }

        if(indexShowTiktok > indexShowOneKidsPage) {
          --this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
          --this.sttShowTiktok;
        }

        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = this.maxCountShow;
        this.sttShowOneKidsPage = this.maxCountShow;

      } else {

        if (indexShowNews === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowOneKidsPage;
          this.sttShowNews = indexShowOneKidsPage;
        }

        if (indexShowWebsiteSchool === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowOneKidsPage;
          this.sttShowWebsiteSchool = indexShowOneKidsPage;
        }

        if (indexShowFacebook === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowOneKidsPage;
          this.sttShowFacebook = indexShowOneKidsPage;
        }

        if (indexShowVideo === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowOneKidsPage;
          this.sttShowVideo = indexShowOneKidsPage;
        }

        if (indexShowTiktok === countActive) {
          this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowOneKidsPage;
          this.sttShowTiktok = indexShowOneKidsPage;
        }
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = countActive;
        this.sttShowOneKidsPage = countActive;
        ++this.numberSelect;
      }
    },

    changeNumberShowNews(value){
      const indexShowNews = this.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowFacebook === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowNews;
        this.sttShowFacebook = indexShowNews;
      }

      if (indexShowTiktok === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowNews;
        this.sttShowFacebook = indexShowNews;
      }

      if (indexShowVideo === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowNews;
        this.sttShowVideo = indexShowNews;
      }

      if (indexShowWebsiteSchool === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowNews;
        this.sttShowWebsiteSchool = indexShowNews;
      }

      if (indexShowOneKidsPage === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowNews;
        this.sttShowOneKidsPage = indexShowNews;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = value;
      this.sttShowNews = value;
    },

    changeNumberShowFacebook(value){
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowFacebook;
        this.sttShowNews = indexShowFacebook;
      }

      if (indexShowTiktok === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowFacebook;
        this.sttShowTiktok = indexShowFacebook;
      }

      if (indexShowVideo === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowFacebook;
        this.sttShowVideo = indexShowFacebook;
      }

      if (indexShowWebsiteSchool === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowFacebook;
        this.sttShowWebsiteSchool = indexShowFacebook;
      }

      if (indexShowOneKidsPage === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowFacebook;
        this.sttShowOneKidsPage = indexShowFacebook;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = value;
      this.sttShowFacebook = value;
    },

    changeNumberShowVideo(value){
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowVideo;
        this.sttShowNews = indexShowVideo;
      }

      if (indexShowFacebook === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowVideo;
        this.sttShowFacebook = indexShowVideo;
      }

      if (indexShowTiktok === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowVideo;
        this.sttShowTiktok = indexShowVideo;
      }

      if (indexShowWebsiteSchool === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowVideo;
        this.sttShowWebsiteSchool = indexShowVideo;
      }

      if (indexShowOneKidsPage === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowVideo;
        this.sttShowOneKidsPage = indexShowVideo;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = value;
      this.sttShowVideo = value;
    },

    changeNumberShowWebsiteSchool(value){
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowWebsiteSchool;
        this.sttShowNews = indexShowWebsiteSchool;
      }

      if (indexShowFacebook === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowWebsiteSchool;
        this.sttShowFacebook = indexShowWebsiteSchool;
      }

      if (indexShowVideo === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowWebsiteSchool;
        this.sttShowVideo = indexShowWebsiteSchool;
      }

      if (indexShowTiktok === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowWebsiteSchool;
        this.sttShowTiktok = indexShowWebsiteSchool;
      }

      if (indexShowOneKidsPage === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowWebsiteSchool;
        this.sttShowOneKidsPage = indexShowWebsiteSchool;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = value;
      this.sttShowWebsiteSchool = value;
    },

    changeNumberShowTiktok(value){
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.sttShowTiktok;
      const indexShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;

      if (indexShowNews === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowTiktok;
        this.sttShowNews = indexShowTiktok;
      }

      if (indexShowFacebook === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowTiktok;
        this.sttShowFacebook = indexShowTiktok;
      }

      if (indexShowVideo === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowTiktok;
        this.sttShowVideo = indexShowTiktok;
      }

      if (indexShowWebsiteSchool === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowTiktok;
        this.sttShowWebsiteSchool = indexShowTiktok;
      }

      if (indexShowOneKidsPage === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = indexShowTiktok;
        this.sttShowOneKidsPage = indexShowTiktok;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = value;
      this.sttShowTiktok = value;
    },

    changeNumberShowOneKidsPage(value){
      const indexShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      const indexShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      const indexShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      const indexShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      const indexShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      const indexShowOneKidsPage = this.sttShowOneKidsPage

      if (indexShowNews === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews = indexShowOneKidsPage;
        this.sttShowNews = indexShowOneKidsPage;
      }

      if (indexShowFacebook === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook = indexShowOneKidsPage;
        this.sttShowFacebook = indexShowOneKidsPage;
      }

      if (indexShowVideo === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo = indexShowOneKidsPage;
        this.sttShowVideo = indexShowOneKidsPage;
      }

      if (indexShowWebsiteSchool === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool = indexShowOneKidsPage;
        this.sttShowWebsiteSchool = indexShowOneKidsPage;
      }

      if (indexShowTiktok === value) {
        this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok = indexShowOneKidsPage;
        this.sttShowTiktok = indexShowOneKidsPage;
      }

      this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage = value;
      this.sttShowOneKidsPage = value;
    },

    async changeNumberSelect() {
      this.numberSelect = 6
      if (this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowWebsiteSchool === false) {
        this.numberSelect--
      }
      if (this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowTiktok === false) {
        this.numberSelect--
      }
      if (this.systemConfigSchoolData.schoolConfigInSysResponse.activeShowOneKidsPage === false) {
        this.numberSelect--
      }
    },

    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs["systemConfigSchoolData"].resetFields();
      }, 300);
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs[fromData].resetFields();
      }, 300);
    },
    resetFormData() {
      this.$refs["systemConfigSchoolData"].resetFields();
    },
    submitForm(fromData) {
      this.loadingButton = true;

      let sttNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews;
      let sttFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook;
      let sttVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo;
      let sttShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool;
      let sttShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok;
      let sttShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage;
      if (sttNews === sttFacebook || sttNews === sttVideo || sttNews === sttShowWebsiteSchool
          || sttNews === sttShowTiktok || sttNews === sttShowOneKidsPage
          || sttFacebook === sttVideo || sttFacebook === sttShowWebsiteSchool || sttFacebook === sttShowTiktok
          || sttFacebook === sttShowOneKidsPage
          || sttVideo === sttShowWebsiteSchool || sttVideo === sttShowTiktok || sttVideo === sttShowOneKidsPage
          || sttShowWebsiteSchool === sttShowTiktok || sttShowWebsiteSchool === sttShowOneKidsPage
          || sttShowTiktok === sttShowOneKidsPage
      ) {
        this.$message({
          message: "Không được chọn thứ tự hiển thị tin tức màn Home trên App giống nhau ",
          type: "error",
          duration: 2000
        });
        this.loadingButton = false;
      } else {
        SystemConfigService.updateSchoolConfigSystem(this.systemConfigSchoolData)
            .then((response) => {
              let data = response.data.data;
              console.log(data);
              this.$message({
                message: "Cập nhật thành công",
                type: "success",
              });

              setTimeout(() => {
                this.closeDialogByButton(fromData);
              }, 500);
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            })
            .finally(() => {
              setTimeout(() => {
                this.loadingButton = false;
              }, 500);
            });
      }
    },
    getDataInitial(id) {
      SystemConfigService.findSchoolConfigSystem(id)
          .then((resp) => {
            this.systemConfigSchoolData = resp.data.data;
            this.sttShowNews = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowNews
            this.sttShowFacebook = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowFacebook
            this.sttShowVideo = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowVideo
            this.sttShowWebsiteSchool = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowWebsiteSchool
            this.sttShowTiktok = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowTiktok
            this.sttShowOneKidsPage = this.systemConfigSchoolData.schoolConfigInSysResponse.sttShowOneKidsPage
            this.changeNumberSelect()
          })
          .catch((err) => {
            console.log(err);
            this.systemConfigSchoolData = null;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.over-table {
  width: 97%;
  margin: 0 auto;
}

.table-data {
  border-collapse: collapse;
  border: 1px solid #ebeef5;
}

@media screen and (max-width: 1366px) {
  .wrapper-table {
    height: 480px;
  }
}

@media screen and (min-width: 1367px) {
  .wrapper-table {
    height: 740px;
  }
}

.input-common {
  width: 60px;
}

@media screen and (max-width: 1366px) {
  .sttShowTabHomeTable {
    width: 300px;
  }
}

@media screen and (min-width: 1367px) {
  .sttShowTabHomeTable {
    width: 300px;
  }
}

.sttShowTabHomeTable {
  overflow-y: scroll;
  overflow-x: scroll;
  border-bottom: 1px solid #ebeef5;
}

.wrapper-table {
  overflow-y: scroll;
  border-top: 1px solid #ebeef5;
}

thead {
  background: #78a5e7;
  color: white;
  font-weight: bold;
}

tbody {
  color: #606266;
  font-size: 14px;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
}

td {
  padding: 10px;
}

.table-index {
  width: 30px;
  text-align: center;
}

.table-item-name {
  text-align: center;
}

.table-action {
  width: 50px;
  text-align: center;
}

.disable-row {
  background: #e4e7ed;
}
</style>
