var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cấu hình giới hạn thiết bị",
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "1vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "140px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "font-weight": "bold", "font-size": "17px" } },
            [_vm._v("Dành cho app onekids")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Phụ huynh" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái", prop: "parentAppStatus" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.parentAppStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "parentAppStatus", $$v)
                          },
                          expression: "dataInput.parentAppStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "parentAppNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.parentAppNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "parentAppNumber", $$v)
                          },
                          expression: "dataInput.parentAppNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Giáo viên" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Trạng thái", prop: "teacherAppStatus" },
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.teacherAppStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "teacherAppStatus", $$v)
                          },
                          expression: "dataInput.teacherAppStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "teacherAppNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.teacherAppNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "teacherAppNumber", $$v)
                          },
                          expression: "dataInput.teacherAppNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Nhà trường" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái", prop: "plusAppStatus" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.plusAppStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "plusAppStatus", $$v)
                          },
                          expression: "dataInput.plusAppStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "plusAppNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.plusAppNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "plusAppNumber", $$v)
                          },
                          expression: "dataInput.plusAppNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-family": "Arial" },
                  attrs: { span: 11 },
                },
                [
                  _vm._v(
                    "\n        Duy trì giới hạn thiết bị, không out thiết bị cũ"
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.dataInput.limitDeviceAppStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "limitDeviceAppStatus", $$v)
                      },
                      expression: "dataInput.limitDeviceAppStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticStyle: { "font-weight": "bold", "font-size": "17px" } },
            [_vm._v("Dành cho app onecam")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Phụ huynh" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái", prop: "parentStatus" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.parentStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "parentStatus", $$v)
                          },
                          expression: "dataInput.parentStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "parentNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.parentNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "parentNumber", $$v)
                          },
                          expression: "dataInput.parentNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Giáo viên" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái", prop: "teacherStatus" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.teacherStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "teacherStatus", $$v)
                          },
                          expression: "dataInput.teacherStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "teacherNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.teacherNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "teacherNumber", $$v)
                          },
                          expression: "dataInput.teacherNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [_c("el-form-item", { attrs: { label: "Nhà trường" } })],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái", prop: "plusStatus" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.plusStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "plusStatus", $$v)
                          },
                          expression: "dataInput.plusStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { float: "right" },
                      attrs: { label: "Số thiết bị", prop: "plusNumber" },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.dataInput.plusNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "plusNumber", $$v)
                          },
                          expression: "dataInput.plusNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-family": "Arial" },
                  attrs: { span: 11 },
                },
                [
                  _vm._v(
                    "\n        Duy trì giới hạn thiết bị, không out thiết bị cũ"
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.dataInput.limitDeviceStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "limitDeviceStatus", $$v)
                      },
                      expression: "dataInput.limitDeviceStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("span", [
        _vm._v(
          "Chú ý: Số thiết bị bằng 0 thì không giới hạn số lượng thiết bị sử\n    dụng"
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }