var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px", "margin-right": "6px" },
                  attrs: { placeholder: "Chọn đại lý", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.findAllAppIconForSchool()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.idAgent,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "idAgent", $$v)
                    },
                    expression: "dataSearch.idAgent",
                  },
                },
                _vm._l(_vm.agentList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.agentName, value: item.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px", "margin-right": "5px" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "Chọn trường",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.findAllAppIconForSchool()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.schoolName,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "schoolName", $$v)
                    },
                    expression: "dataSearch.schoolName",
                  },
                },
                _vm._l(_vm.schoolList, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        value: item.schoolName,
                        label: item.id + " - " + item.schoolName,
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(item.id))]),
                      _vm._v(" -\n          "),
                      _c("span", [_vm._v(_vm._s(item.schoolName))]),
                    ]
                  )
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "170px", "margin-right": "5px" },
                  attrs: { placeholder: "Chọn trường" },
                  on: {
                    change: function ($event) {
                      return _vm.findAllAppIconForSchool()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.schoolActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "schoolActive", $$v)
                    },
                    expression: "dataSearch.schoolActive",
                  },
                },
                _vm._l(_vm.schoolActiveList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                "highlight-current-row": "",
                data: _vm.appIconForSchoolList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  align: "center",
                  width: "55",
                  fixed: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                  fixed: "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tên trường", "min-width": "150", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.id) +
                              " - " +
                              _vm._s(scope.row.schoolName)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tình trạng", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.schoolActive
                          ? _c("span", [_vm._v("Kích hoạt")])
                          : _c("span", [_vm._v("Chưa kích hoạt")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Notify Firebase",
                  align: "center",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingNotifyMethod(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Icon lock", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingIconLockMethod(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "App OneKids", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingAppIconParent(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "App Teacher", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingAppIconTeacher(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "App Plus", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingAppIconPlus(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Giới hạn thiết bị",
                  align: "center",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.oneCamConfigMethod(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "School config",
                  align: "center",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.settingSchoolConfig(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Thiết lập\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-dropdown",
                {
                  staticStyle: { float: "right", "margin-top": "10px" },
                  on: { command: _vm.handleCommandAction },
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "button-over", attrs: { type: "success" } },
                    [
                      _vm._v("\n          Tác vụ\n          "),
                      _c("i", { staticClass: "el-icon-caret-bottom" }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: {
                            command: "turnOffAutomaticInvoiceGeneration",
                          },
                        },
                        [_vm._v("Tắt tự động sinh hóa đơn")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AttendanceSampleUpdate", {
        ref: "AttendanceSampleUpdate",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
      _c("SchoolSystemIconParentDialog", {
        ref: "SchoolSystemIconParentDialog",
        attrs: { dialogVisible: _vm.showIconParentDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateIconParentMethod()
          },
        },
      }),
      _c("SchoolSystemIconTeacherDialog", {
        ref: "SchoolSystemIconTeacherDialog",
        attrs: { dialogVisible: _vm.showIconTeacherDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateIconTeacherMethod()
          },
        },
      }),
      _c("SchoolSystemIconPlusDialog", {
        ref: "SchoolSystemIconPlusDialog",
        attrs: { dialogVisible: _vm.showIconPlusDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateIconPlusMethod()
          },
        },
      }),
      _c("SchoolSystemConfigDialog", {
        ref: "SchoolSystemConfigDialog",
        attrs: { dialogVisible: _vm.showSchoolConfigDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseSchoolConfigMethod()
          },
        },
      }),
      _c("NotifyFirebaseDialog", {
        ref: "NotifyFirebaseDialog",
        attrs: { dialogVisible: _vm.showNotifyFirebaseDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesNotifyFirebaseDialogMethod()
          },
        },
      }),
      _c("IconLockDialog", {
        ref: "IconLockDialog",
        attrs: { dialogVisible: _vm.showIconLockDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesIconLockDialogMethod()
          },
        },
      }),
      _c("OneCamConfigDialog", {
        ref: "OneCamConfigDialog",
        attrs: { dialogVisible: _vm.showOneCamConfigDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOneCamConfigDialogMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }